import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div className="footer-area">
                <div className="footer-container">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                                    <div className="single-wedge">
                                        <div className="footer-logo">
                                            <Link to={"/"}>
                                                <img
                                                    className="img-responsive"
                                                    src={process.env.REACT_APP_MAIN_URL+"/images/logo/logo.png"}
                                                    alt="logo.jpg"
                                                    Style="width: 150px;"
                                                />
                                            </Link>
                                        </div>
                                        <p className="text-infor">
                                            From powerful PCs to sleek laptops, we've got everything you need to fuel your digital dreams. Shop now for the ultimate tech experience!
                                        </p>

                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-sm-6 mb-md-30px mb-lm-30px">
                                    <div className="need_help">
                                        <p className="add">
                                            <span className="address">Address:</span> Main Market, Rudrapur Udham Singh Nagar,<br />
                                            Uttrakhand-263153
                                        </p>
                                        <p className="mail">
                                            <span className="email">Email:</span>{" "}
                                            <a href="mailto:support@hasthemes.com">
                                                info@pc2u.in
                                            </a>
                                        </p>
                                        <p className="phone">
                                            <span className="call us">Call Us:</span>{" "}
                                            <a href="tel:(+91)12345678"> (+91)12345678</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-sm-6 mb-sm-30px mb-lm-30px">
                                    <div className="single-wedge">
                                        <h4 className="footer-herading">Information</h4>
                                        <div className="footer-links">
                                            <ul>
                                                <li>
                                                    <Link to={"/terms-of-service"}>Terms of Service</Link>
                                                </li>
                                                <li>
                                                    <Link to={"/cancellation-and-refund"} >Cancellation and Refund</Link>
                                                </li>
                                                <li>
                                                    <Link to={"/privacy-policy"} >Privacy Policy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="footer-paymet-warp d-flex">
                                        <div className="heading-info">Payment:</div>
                                        <div className="payment-way">
                                            <img
                                                className="payment-img img-responsive"
                                                src="../images/icons/payment.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-social-icon d-flex">
                                        <div className="heading-info">Follow Us:</div>
                                        <div className="social-icon">
                                            <ul>
                                                <li className="facebook">
                                                    <a href="#">
                                                        <i className="ion-social-facebook" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="#">
                                                        <i className="ion-social-twitter" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="#" Style="background-color: #e91e63">
                                                        <i className="ion-social-instagram" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-tags">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="copy-text">
                                        Copyright © <a href="https://pc2u.in/">pc2u.in</a>. All
                                        Rights Reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer