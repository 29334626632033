import { useState, useCallback } from 'react'


function Account() {


    // const [crop, setCrop] = useState({ x: 0, y: 0 })
    // const [zoom, setZoom] = useState(1)
    const [openCropper, setOpenCropper] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [imageBase64, setImageBase64] = useState()

    const [validationError, setValidationError] = useState({
        fieldType: "",
        error: ""
    })
    const [profile, setProfile] = useState({
        first_name: "",
        last_name: "",
        mobile: ""
    })
    const [changePass, setChangePass] = useState({
        new_password: "",
        old_password: "",
        confirm_password: ""
    })

    // const onCropComplete = (croppedArea, croppedAreaPixels) => {
    //     console.log(croppedArea, croppedAreaPixels)
    // }

    async function imageSave(token, image) {
        const response = await fetch(`${process.env.REACT_APP_URL}user_api/user/changeProfilePic`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                token: token,
                picture: image
            })
        })
        return response.json()
    }

    async function savePasswordApi(token, old_pass, new_pass) {
        const response = await fetch(`${process.env.REACT_APP_URL}user_api/user/changePassword`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                token: token,
                old_password: old_pass,
                new_password: new_pass
            })
        })
        return response.json()
    }

    async function saveProfileApi(token) {
        const response = await fetch(`${process.env.REACT_APP_URL}user_api/user/updateUserProfile`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                first_name: profile.first_name,
                last_name: profile.last_name,
                mobile: profile.mobile,
                token: token
            })
        })
        return response.json()
    }

    function imageSelect(value) {
        setImageSrc(value)
        setOpenCropper(true)
        var reader = new FileReader();
        reader.readAsDataURL(document.getElementById('profileImageInput').files[0]);
        reader.onload = function () {
            console.log(reader.result);
            document.getElementById('profileImgShow').src=reader.result;
            imageSave(JSON.parse(localStorage.getItem("userData")).token, reader.result).then((data)=>{
                console.log(data)
            }).catch((error)=>{
                console.log(error)
            })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    // const [state, setState] = useState({
    //     src: null,
    //     crop: {
    //         unit: '%',
    //         width: "30",
    //         aspect: "16 / 9"
    //     }
    // })

    function passwordChange(){

    }

    function profileChange(){

    }

    return (
        <div>

            <div class="checkout-area mtb-50px">
                <div class="container">
                    <div class="row">
                        <div class="ms-auto me-auto col-lg-4 text-center ">

                            {/* {openCropper ? <Cropper
                                image={"https://admin.pc2u.in/affiliateassets/images/profile_pictures/ACC8012499.png"}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            /> : ""} */}

                            <div className='profileImg '>
                                <img id="profileImgShow" src={`${process.env.REACT_APP_URL + JSON.parse(localStorage.getItem("userData")).picture}`} />
                                <label for="profileImageInput" className='profileInput'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" /></svg></label>
                                <input type="file" className='d-none' id="profileImageInput" accept='image/*' onChange={(e) => { imageSelect(e.target.value) }} />
                            </div>
                            <div className='profileDetails mt-3'>
                                <h5>{JSON.parse(localStorage.getItem("userData")).first_name + " " + JSON.parse(localStorage.getItem("userData")).last_name}</h5>
                                <p>{JSON.parse(localStorage.getItem("userData")).email}</p>
                                <p>{JSON.parse(localStorage.getItem("userData")).phone}</p>
                                <li onClick={() => { localStorage.removeItem("userData"); window.location.reload() }} Style="cursor:pointer; padding: 10px;background-color: #266cfb;  border-radius: 5px; color: white;">
                                    <i class="fa-solid fa-arrow-right-from-bracket me-1"></i>
                                    <a class="dropdown-item-custom text-white" >Log Out</a>
                                </li>
                            </div>
                        </div>
                        <div class="ms-auto me-auto col-lg-8">
                            <div class="checkout-wrapper">
                                <div id="faq" class="panel-group">
                                    <div class="panel panel-default single-my-account">
                                        <div class="panel-heading my-account-title">
                                            <h3 class="panel-title"><span>1 .</span> <a data-bs-toggle="collapse" data-bs-target="#my-account-1">Edit your account information </a></h3>
                                        </div>
                                        <div id="my-account-1" class="panel-collapse collapse show" data-bs-parent="#faq">
                                            <div class="panel-body">
                                                <div class="myaccount-info-wrapper">
                                                    <div class="account-info-wrapper">
                                                        <h4>My Account Information</h4>
                                                        <h5>Your Personal Details</h5>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="billing-info">
                                                                <label>First Name</label>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="billing-info">
                                                                <label>Last Name</label>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="billing-info">
                                                                <label>Mobile</label>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="billing-back-btn">
                                                        <div class="billing-back">
                                                            <a href="#"><i class="fa fa-arrow-up"></i> back</a>
                                                        </div>
                                                        <div class="billing-btn">
                                                            <button type="submit" onClick={profileChange}>Continue</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default single-my-account">
                                        <div class="panel-heading my-account-title">
                                            <h3 class="panel-title"><span>2 .</span> <a data-bs-toggle="collapse" data-bs-target="#my-account-2">Change your password </a></h3>
                                        </div>
                                        <div id="my-account-2" class="panel-collapse collapse" data-bs-parent="#faq">
                                            <div class="panel-body">
                                                <div class="myaccount-info-wrapper">
                                                    <div class="account-info-wrapper">
                                                        <h4>Change Password</h4>
                                                        <h5>Your Password</h5>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="billing-info">
                                                                <label>Password</label>
                                                                <input type="password" value={changePass.new_password} onChange={(e)=>{setChangePass({...changePass, new_password: e.target.value})}} />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="billing-info">
                                                                <label>Password Confirm</label>
                                                                <input type="password" value={changePass.confirm_password} onChange={(e)=>{setChangePass({...changePass, confirm_password: e.target.value})}} />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="billing-info">
                                                                <label>Old Password</label>
                                                                <input type="password" value={changePass.old_password} onChange={(e)=>{setChangePass({...changePass, old_password: e.target.value})}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="billing-back-btn">
                                                        <div class="billing-back">
                                                            <a href="#"><i class="fa fa-arrow-up"></i> back</a>
                                                        </div>
                                                        <div class="billing-btn">
                                                            <button type="submit" onClick={passwordChange}>Continue</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default single-my-account d-none">
                                        <div class="panel-heading my-account-title">
                                            <h3 class="panel-title"><span>3 .</span> <a data-bs-toggle="collapse" data-bs-target="#my-account-3">Modify your address book entries </a></h3>
                                        </div>
                                        <div id="my-account-3" class="panel-collapse collapse" data-bs-parent="#faq">
                                            <div class="panel-body">
                                                <div class="myaccount-info-wrapper">
                                                    <div class="account-info-wrapper">
                                                        <h4>Address Book Entries</h4>
                                                    </div>
                                                    <div class="entries-wrapper">
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                                                <div class="entries-info text-center">
                                                                    <p>Jone Deo</p>
                                                                    <p>Abelo</p>
                                                                    <p>28 Green Tower,</p>
                                                                    <p>Street Name.</p>
                                                                    <p>New York City,</p>
                                                                    <p>USA</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                                                                <div class="entries-edit-delete text-center">
                                                                    <a class="edit" href="#">Edit</a>
                                                                    <a href="#">Delete</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="billing-back-btn">
                                                        <div class="billing-back">
                                                            <a href="#"><i class="fa fa-arrow-up"></i> back</a>
                                                        </div>
                                                        <div class="billing-btn">
                                                            <button type="submit">Continue</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default single-my-account d-none">
                                        <div class="panel-heading my-account-title">
                                            <h3 class="panel-title"><span>4 .</span> <a href="wishlist.html">Modify your wish list </a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Account