import React, { useEffect } from 'react'
import $ from "jquery"
import HeroBamnner from './HeroBamnner';
import FeaturedProducts from '../products/FeaturedProducts';
import HotDeals from '../products/HotDeals';
import BestProducts from '../products/BestProducts';
import NewArrivals from '../products/NewArrivals';
import HomeProducts from '../products/HomeProducts';
import VendorSlider from '../sliders/VendorSlider';
import useHomeFetch from '../../hooks/useHomeFetch';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../services/CartSlice';

function MainHome({products, popularProduct, loading, newArrivalProduct, trendingProduct, setAlert, alert}) {
    useEffect(() => {
        $('[data-bg-image]').each(function () {
            var $this = $(this),
                $image = $this.data('bg-image');
            $this.css('background-image', 'url(' + $image + ')');
        });
    }, [])

    const dispatch=useDispatch()

    function addToCartFunc(item) {
        var itemDetails = { ...item, referral_id: "xyz" }
        updateCart(item.product_id, JSON.parse(localStorage.getItem("userData")).token, "add", "").then((resData) => {
            try {
              // console.log(resData.json())
              if (resData.status == "success") {
                dispatch(addToCart(itemDetails))
                setAlert({...alert, open: true, type: "success", alertType: "modal", msg: "Added to cart Successfully!"})
              } else {
                // bottomsheet need out of stock
                setAlert({...alert, open: true, type: "danger", alertType: "modal", msg: resData.message})

              }
            } catch (error) {
              console.log(error)
            }
          }).catch(error => {
            console.log(error);
          })
    }

    async function updateCart(item, token, type, referId) {
        const response = await fetch(`${process.env.REACT_APP_URL}cart_api/cart/cartUpdateNative`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                token: token,
                product_id: item,
                type: type,
                quantity: 1,
                referId: referId
            })
        });
        return response.json();
    }


    return (
        <>
            <div className="offcanvas-overlay" />
            
            <div className="slider-area">
                <HeroBamnner />
            </div>
            
            <div className="static-area mtb-50px">
                <div className="container">
                    <div className="static-area-wrap">
                        <div className="row">
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-md-30px mb-lm-30px">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-1.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>Free Shipping</h4>
                                        <p>On all orders over $75.00</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-md-30px mb-lm-30px">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-2.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>Free Returns</h4>
                                        <p>Returns are free within 9 days</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-sm-30px">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-3.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>100% Payment Secure</h4>
                                        <p>Your payment are safe with us.</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 ">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-4.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>Support 24/7</h4>
                                        <p>Contact us 24 hours a day</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="feature-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Featured Products</h2>
                                    </div>
                                </div>
                            </div>
                            <FeaturedProducts popularProduct={popularProduct} addToCartFunc={addToCartFunc}/>
                        </div>
                        {/* Feature Area End */}
                        <div className="col-lg-4 col-md-4 mt-lm-30px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Hot Deals</h2>
                                    </div>
                                </div>
                            </div>
                            <HotDeals addToCartFunc={addToCartFunc}/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="banner-area mt-30px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/1.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="best-sell-area mt-20px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Best Products</h2>
                            </div>
                        </div>
                    </div>
                    <BestProducts trendingProduct={trendingProduct} addToCartFunc={addToCartFunc}/>
                </div>
            </div>
            
            <div className="banner-area mt-30px mb-20px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 ">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/2.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/3.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/4.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="arrival-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>New Arrivals</h2>
                                {/* Nav tabs */}
                                {/* <ul className="nav nav-tabs sub-category">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            data-bs-toggle="tab"
                                            href="#tab-1"
                                        >
                                            Home Audio
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                                            Headphones
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                                            Cameras
                                        </a>
                                    </li>
                                </ul> */}
                                {/* Nav tabs */}
                            </div>
                        </div>
                    </div>

                    {/* tab content */}
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane active fade">
                            <NewArrivals newArrivalProduct={newArrivalProduct} addToCartFunc={addToCartFunc} />
                        </div>
                        {/* <div id="tab-2" className="tab-pane fade">
                            <NewArrivals newArrivalProduct={newArrivalProduct} />
                        </div>
                        <div id="tab-3" className="tab-pane fade">
                            <NewArrivals newArrivalProduct={newArrivalProduct} />
                        </div> */}
                    </div>
                    {/* tab content end*/}

                </div>
            </div>
            
            <div className="banner-area mt-20px mb-20px ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/5.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/6.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/7.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="category-slider-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Home Audio</h2>
                                    </div>
                                </div>
                            </div>
                            <HomeProducts catId={1} addToCartFunc={addToCartFunc}/>
                        </div>
                        <div className="col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Headphones</h2>
                                    </div>
                                </div>
                            </div>
                            <HomeProducts catId={1} addToCartFunc={addToCartFunc} />
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Television &amp; Video</h2>
                                    </div>
                                </div>
                            </div>
                            <HomeProducts catId={1} addToCartFunc={addToCartFunc} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Catogery slider area End*/}
            {/* Brand area start */}
            <div className="brand-area mt-20px">
                <div className="container">
                    <VendorSlider />
                </div>
            </div>
            
        </>
    )
}

export default MainHome