import React from 'react'
import Slider from 'react-slick'
import useProByCatFetch from '../../hooks/useProByCatFetch'
import { Link } from 'react-router-dom'

function HomeProducts({ catId, addToCartFunc }) {
    const { prodDetLoading, error, trendingCatProduct } = useProByCatFetch(catId)
    const settings = {
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
        // prevArrow: '<span class="prev"><i class="ion-ios-arrow-left"></i></span>',
        // nextArrow: '<span class="next"><i class="ion-ios-arrow-right"></i></span>',
        speed: 800,
        easing: 'linear',
        dots: false,
        autoplay: true
    }
    console.log(trendingCatProduct)
    return (
        <div className="category-slider-wraper slider-nav-style-1">
            <Slider {...settings} >
                <div className="slider-single-item">
                    {/* Single Item */}
                    {trendingCatProduct.length > 0 ?
                        trendingCatProduct.map((item, index) => {
                            return index < 4 ? <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <Link to={`/product/${item.product_id}`} className="thumbnail">
                                            <img
                                                className="home-product-img first-img"
                                                src={process.env.REACT_APP_URL + item.thumbnail}
                                                alt={item.product_name}
                                            />
                                            <img
                                                className="home-product-img second-img"
                                                src={process.env.REACT_APP_URL + item.thumbnail}
                                                alt={item.product_name}
                                            />
                                        </Link>
                                    </div>
                                    <div className="product-decs">
                                        <h2>
                                            <Link to={`/product/${item.product_id}`} className="product-link">
                                                {item.product_name.length > 65 ? item.product_name.slice(0, 64) + "..." : item.product_name}
                                            </Link>
                                        </h2>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">₹{Number(item.discounted_price).toFixed(2)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article> : ""
                        })
                        :
                        ""}
                </div>
                {<div className="slider-single-item">
                    {trendingCatProduct.length > 0 ?
                        trendingCatProduct.map((item, index) => {
                            return index > 3 && index < 7 ? <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <Link to={`/product/${item.product_id}`} className="thumbnail">
                                            <img
                                                className="home-product-img first-img"
                                                src={process.env.REACT_APP_URL + item.thumbnail}
                                                alt={item.product_name}
                                            />
                                            <img
                                                className="home-product-img second-img"
                                                src={process.env.REACT_APP_URL + item.thumbnail}
                                                alt={item.product_name}
                                            />
                                        </Link>
                                    </div>
                                    <div className="product-decs">
                                        <h2>
                                            <Link to={`/product/${item.product_id}`} className="product-link">
                                                {item.product_name.length > 65 ? item.product_name.slice(0, 64) + "..." : item.product_name}
                                            </Link>
                                        </h2>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">₹{Number(item.discounted_price).toFixed(2)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article> : <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <Link to={`/product/${item.product_id}`} className="thumbnail">
                                            <img
                                                className="home-product-img first-img"
                                                src={process.env.REACT_APP_URL + item.thumbnail}
                                                alt={item.product_name}
                                            />
                                            <img
                                                className="home-product-img second-img"
                                                src={process.env.REACT_APP_URL + item.thumbnail}
                                                alt={item.product_name}
                                            />
                                        </Link>
                                    </div>
                                    <div className="product-decs">
                                        <h2>
                                            <Link to={`/product/${item.product_id}`} className="product-link">
                                                {item.product_name.length > 65 ? item.product_name.slice(0, 64) + "..." : item.product_name}
                                            </Link>
                                        </h2>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">₹{Number(item.discounted_price).toFixed(2)}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        })
                        :
                        ""}
                </div>}
            </Slider>
        </div>
    )
}

export default HomeProducts