import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux'
import CheckoutForm from './CheckoutForm';
import { useParams } from 'react-router-dom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51OrAw0SDWBpGH7Ry6daf1iybbjUSOXprzp5oN7MZAj8Edhl6VJm5slf8YCaP6pmkghgD05fRBy33GyFjE6nPXSUV00GrvJS10i');

function Payment({ clientSecret }) {
    var { clientSecret, orderId } = useParams()
    const cartData = useSelector((state) => (state.counter))
    const [totalCartAmount, setTotalCartAmount] = useState(0)
    const [totaltax, settotaltax] = useState(0)
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))

    useEffect(() => {
        var totalValue = 0;
        var totalTaxValue = 0
        cartData.value.forEach(item => {
            totalValue = totalValue + (Number(item.discounted_price) * Number(item.quantity))
            totalTaxValue = totalTaxValue + (Number(item.tax) * Number(item.quantity))
        });
        setTotalCartAmount(totalValue)
        settotaltax(totalTaxValue)
    }, [cartData.value])

    useEffect(() => {
        document.getElementsByClassName("header-wrapper")[0].style.display = "none"
        document.getElementsByClassName("footer-container")[0].style.display = "none"
    }, [])


    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: 'flat',
            variables: { colorPrimaryText: '#262626' }
        },
    };

    return (
        <div className='container mt-5 mb-5' Style="max-width: 1000px !important">
            <div className=' row paymentContainer'>
                <div className='col-md-5'>
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm amount={totalCartAmount.toFixed(2)} orderId={orderId}/>
                    </Elements>
                </div>
                <div className='col-md-7'>
                    <div class="your-order-area">
                        <div class="your-order-wrap gray-bg-4">
                            <div class="your-order-product-info">
                                <div className='mt-3 d-flex align-items-center'>
                                    <div>
                                        <i class="fa-solid fa-arrow-left"></i>
                                    </div>
                                    <div className='ms-2'>
                                        <img
                                            className="img-responsive"
                                            src="../images/logo/logo.png"
                                            alt="logo.jpg"
                                            Style="width: 50px;"
                                        />
                                    </div>
                                    <div className='ms-2'>
                                        <h6 className='mb-0'>pc2u.in</h6>
                                    </div>
                                </div>
                                <div class="your-order-middle">
                                    <ul>
                                        {cartData.value.map((item) => {
                                            return <li><span class="order-middle-left">{item.product_name.length > 24 ? item.product_name.slice(0, 21) + "..." : item.product_name} X {item.quantity}</span> <span class="order-price">₹{(Number(item.discounted_price) * Number(item.quantity)).toFixed(2)} </span></li>
                                        })}
                                    </ul>
                                </div>
                                <div class="your-order-bottom">
                                    <ul>
                                        <li class="your-order-shipping">Shipping</li>
                                        <li>Free shipping</li>
                                    </ul>
                                </div>
                                <div class="your-order-bottom">
                                    <ul>
                                        <li class="your-order-shipping">Tax</li>
                                        <li>₹{totaltax.toFixed(2)}</li>
                                    </ul>
                                </div>
                                <div class="your-order-total">
                                    <ul>
                                        <li class="order-total">Total</li>
                                        <li>₹{totalCartAmount.toFixed(2)}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payment