import './App.css';
import Home from './components/Home';

import '../src/assets/css/plugins/animate.css'
import '../src/assets/css/plugins/jquery-ui.min.css'
import '../src/assets/css/plugins/slick.css'

import '../src/assets/css/vendor/bootstrap.min.css'
import '../src/assets/css/vendor/font-awesome.min.css'
import '../src/assets/css/vendor/ionicons.min.css'
import '../src/assets/css/vendor/linearicon.css'

import MobileHeader from './components/header/MobileHeader';
import Header from './components/header/Header';

import { BrowserRouter as Router, Routes, Route, useLocation  } from "react-router-dom";
import Footer from './components/footer/Footer';
import Login from './components/auth/Login';

import { useEffect, useState } from 'react';
import GlobalAlert from './components/GlobalAlert';
import Register from './components/auth/Register';
import Account from './components/account/Account';
import ProductDetails from './components/products/ProductDetails';
import useHomeFetch from './hooks/useHomeFetch';
import { Provider } from 'react-redux';
import Store from './services/Store';
import Cart from './components/cart/Cart';
import TermsandCondition from './components/information/TermsandCondition';
import PrivacyPolicy from './components/information/PrivacyPolicy';
import ContactUs from './components/information/ContactUs';
import AboutUs from './components/information/AboutUs';
import Cancelation from './components/information/Cancelation';
import Checkout from './components/cart/Checkout';
import Stripe from './components/cart/Stripe';
import Payment from './components/cart/Payment';
import OrderSuccess from './components/cart/OrderSuccess';
import Order from './components/account/Order';
import ParticularOrder from './components/account/ParticularOrder';
import useCategoryFetch from './hooks/useCategoryFetch';
import AllProducts from './components/products/AllProducts';

function App() {
  const [alert, setAlert] = useState({
    open: false,
    msg: "",
    type: "success",
    alertType: "alert"
  })
  const { products, loading, popularProduct, newArrivalProduct, trendingProduct, prodListLoading, topBanner, error } = useHomeFetch()

  const {allCategory, catloading}=useCategoryFetch()
  
  const [orderId, setorderId] = useState("")

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Provider store={Store}>
      <div className="App">
        <Router>
          <Header allCategory={allCategory}/>
          <MobileHeader allCategory={allCategory}/>
          <GlobalAlert alert={alert} setAlert={setAlert} />
          <Routes>
            <Route exact path='/' element={<Home alert={alert} setAlert={setAlert} products={products} popularProduct={popularProduct} trendingProduct={trendingProduct} newArrivalProduct={newArrivalProduct} loading={loading} />} />
            <Route path='/login' element={<Login alert={alert} setAlert={setAlert} />} />
            <Route path='/register' element={<Register alert={alert} setAlert={setAlert} />} />
            <Route path='/account' element={<Account alert={alert} setAlert={setAlert} />} />
            <Route path='/cart' element={<Cart alert={alert} setAlert={setAlert} />} />
            <Route path='/checkout' element={<Checkout alert={alert} setAlert={setAlert} setorderId={setorderId} orderId={orderId}/>} />
            <Route path='/product/:prodId' element={<ProductDetails alert={alert} setAlert={setAlert} />} />
            <Route path='/terms-of-service' element={<TermsandCondition alert={alert} setAlert={setAlert} />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy alert={alert} setAlert={setAlert} />} />
            <Route path='/contactus' element={<ContactUs alert={alert} setAlert={setAlert} />} />
            <Route path='/aboutus' element={<AboutUs alert={alert} setAlert={setAlert} />} />
            <Route path='/cancellation-and-refund' element={<Cancelation alert={alert} setAlert={setAlert} />} />
            <Route path='/orderSuccess/:orderId' element={<OrderSuccess alert={alert} setAlert={setAlert} orderId={orderId}/>} />
            <Route path='/payment/:clientSecret/:orderId' element={<Payment alert={alert} setAlert={setAlert} />} />
            <Route path='/orders' element={<Order alert={alert} setAlert={setAlert} />} />
            <Route path='/particularorder/:orderId' element={<ParticularOrder alert={alert} setAlert={setAlert} />} />
            <Route path='/product/:type/:typeId' element={<AllProducts alert={alert} setAlert={setAlert} />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
