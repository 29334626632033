import React, { useEffect, useState } from 'react'
import orderImage from "../../assets/gifs/order.gif"

function Order() {
    const [allOrders, setAllOrders] = useState([])
    async function getOrderList(token) {
        const response = await fetch(`${process.env.REACT_APP_URL}order_api/order/usersAllOrders`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                token: JSON.parse(localStorage.getItem("userData")).token,
            })
        });
        return response.json();
    }
    useEffect(() => {
        getOrderList().then((data)=>{
            console.log(data)
            if(data.status==="success"){
                setAllOrders(data.allOrders)
            }
        })
    }, [])

    
    
    return (
        <div className='orders'>
            <div className='container' >
                <div class="inner mt-5 mb-5">
                    <div class="body customScroll">
                        <ul class="minicart-product-list">

                            {allOrders.length>0?allOrders.map((item)=>{
                                return <li>
                                <a href="single-product.html" class="image"><img src={process.env.REACT_APP_URL+item.thumbnail} alt="Cart product Image" /></a>
                                <div class="content">
                                    <a href="single-product.html" class="title w-75">{item.product_name}</a>
                                    <span class="quantity-price"><span class="amount"><span Style="color: #6e6e6e">Qty:</span> {item.quantity}</span></span>
                                    <span class="quantity-price"><span class="amount"><span Style="color: #6e6e6e">Price:</span> ₹{Number(item.order_value).toFixed(2)}</span></span>
                                    <span class="quantity-price"><span class="amount"><span Style="color: #6e6e6e">Date:</span> {new Date(item.order_time).toDateString()}</span></span>
                                    <button className='btn btn-success remove text-light'>Details</button>
                                </div>
                            </li>
                            }):<><div className='d-flex justify-content-center'><img src={orderImage}/></div><h4 className='d-flex justify-content-center'>No order found!</h4></>}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Order