import React, { useEffect } from 'react'
import aboutUsImg from "../../assets/gifs/Collab.gif"

function AboutUs() {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <section class="about-area mtb-50px">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-left-image mb-md-30px mb-lm-30px ">
              <img src={aboutUsImg} alt="" class="img-responsive" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <div class="about-title">
                <h2>About Us</h2>
              </div>
              <p class="mb-30px">
                Welcome to pc2u.in - Your Ultimate Destination for Everything Tech
              </p>
              <p class="mb-30px">
                At pc2u.in, we're not just a computer ecommerce website; we're your partners in exploring the limitless possibilities of technology. With a passion for innovation and a commitment to customer satisfaction, we strive to be your one-stop destination for all your tech needs.
              </p>
              <h5 class="mb-30px">
                Our Story:
              </h5>
              <p>
                Founded by a team of tech enthusiasts, [Website Name] was born out of a shared love for all things digital. We recognized the need for a platform that not only offers the latest and greatest in computer hardware and software but also provides expert guidance and support to customers at every step of their tech journey.
              </p>
              <h5 class="mb-30px">
                What We Offer:
              </h5>
              <p>
                From cutting-edge gaming rigs to sleek laptops for productivity, we curate a diverse selection of top-quality products from leading brands around the globe. Whether you're a seasoned pro or a novice explorer in the world of technology, we have something for everyone.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <h5 class="mb-30px">
            Why Choose Us:
          </h5>
          <p>
            <span className='subHead'>Unrivaled Selection:</span> Browse through our extensive catalog of computers, peripherals, accessories, and more to find the perfect fit for your needs.
          </p>
          <p>
            <span className='subHead'>Expert Advice:</span> Our team of tech-savvy professionals is here to answer your questions, offer recommendations, and guide you towards making informed decisions.
          </p>
          <p>
            <span className='subHead'>Exceptional Service:</span> We prioritize your satisfaction above all else, providing fast shipping, hassle-free returns, and dedicated customer support every step of the way.
          </p>
          <p>
            <span className='subHead'>Community Engagement:</span> Join our thriving community of tech enthusiasts, where you can share knowledge, exchange ideas, and stay updated on the latest trends and innovations in the tech world.
          </p>
        </div>
      </div>
    </section>
  )
}

export default AboutUs