import React, { useState, useEffect} from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


function Stripe() {
    async function stripeCheckout(sessionId){
        const stripe = await loadStripe('pk_test_51O7uGYSGyYMECnfFDJ6UP0HcVHDF74rNJr8Ynt3M1SRV1OTW9lH8Cqy7ZsCQfVcuJJY2XrPK0Lp1pMMr08IDDW2R00Lhr4LgD8');
        stripe.redirectToCheckout({ sessionId:sessionId }).then(handleResult);
    }
    
    function createPayment(price_id,type,mode) {
        createCheckoutSession(price_id,type,mode).then((data)=>{
            console.log(data);
            if (data.sessionId) {
                stripeCheckout(data.sessionId)
            } else {
                console.log('wrong stripe credential')
            }
        });
    }

    const handleResult = (result)=> {
        console.log(result)
	};


    const createCheckoutSession = async function (price_id,type,mode) {
        // var countryCode = document.getElementById('country').value
        // var subscriptionType = document.getElementById('durationTrack').value
        const response = await fetch("https://scrubber.codeulas.com/account/subscription/paymentinit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                createCheckoutSession: 1,
                package: price_id,
                mde: mode,
                customer_email: "debjyoti.codeulas@gmail.com",
                country: 85,
                subscriptionType: type
            }),
        });

        return response.json();
    };

    async function createStripeIntent() {
        const response = await fetch(`${process.env.REACT_APP_URL}order_api/paymentintent/paymentIntentCreate`, {
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            amount: 505 * 100,
            name: "kaushik",
            phone: "8456968523",
            email: "kaushik@codeulas.com",
            address: {
              city: "kolkata",
              line1: "kalyani",
              line2: "nadia",
              postalCode: 741295,
              country: "india",
              state: "west bengal"
            }
          })
        });
        return response.json();
    
      }

    return (
        <div>
            <div class="container">
                <div class="card-deck mb-3 text-center row mt-5">
                    <div class="card mb-4 shadow-sm col-4 p-2">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Free</h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">$0 <small class="text-muted">/ mo</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>5 users included</li>
                                <li>2 GB of storage</li>
                                <li>Email support</li>
                                <li>Help center access</li>
                            </ul>
                            <button type="button" class="btn btn-lg btn-block btn-outline-primary" >Sign up for free</button>
                        </div>
                    </div>
                    <div class="card mb-4 shadow-sm col-4 p-2">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Pro</h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">$25 <small class="text-muted">/ mo</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>20 users included</li>
                                <li>10 GB of storage</li>
                                <li>Priority email support</li>
                                <li>Help center access</li>
                            </ul>
                            <button type="button" class="btn btn-lg btn-block btn-primary" onClick={()=>{createPayment('price_1OEqhdSGyYMECnfFkrVDYcte','monthly',0)}}>upgrade</button>
                        </div>
                    </div>
                    <div class="card mb-4 shadow-sm col-4">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Lifetime</h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">$99 <small class="text-muted">/ mo</small></h1>
                            <ul class="list-unstyled mt-3 mb-4">
                                <li>illimited users included</li>
                                <li>50 GB of storage</li>
                                <li>Phone and email support</li>
                                <li>Help center access</li>
                            </ul>
                            <button type="button" class="btn btn-lg btn-block btn-primary" onClick={()=>{createPayment('price_1OEqk7SGyYMECnfFp3i081Kc', 'onetime',1)}}>Upgrade</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Stripe