import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cartImg from "../../assets/gifs/cart.gif"
import { addToCart, decreaseCart, getcartAllData, removeFromCart } from '../../services/CartSlice'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Cart({setAlert}) {
    const cartData = useSelector((state) => (state.counter))
    const dispatch=useDispatch()
    const [totalCartAmount, setTotalCartAmount] = useState(0)
    const [totaltax, settotaltax] = useState(0)
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(()=>{
        if(localStorage.getItem("userData")){
            dispatch(getcartAllData(JSON.parse(localStorage.getItem("userData")).token))
        }
    },[])

    useEffect(() => {
        var totalValue=0;
        var totalTaxValue=0
        cartData.value.forEach(item => {
            totalValue=totalValue+(Number(item.discounted_price) * Number(item.quantity))
            totalTaxValue=totalTaxValue+(Number(item.tax) * Number(item.quantity))
        });
        setTotalCartAmount(totalValue)
        settotaltax(totalTaxValue)
    }, [cartData.value])
    
    async function updateCart(item, token, type) {
        const response = await fetch(`${process.env.REACT_APP_URL}cart_api/cart/cartUpdateNative`, {
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            token: token,
            product_id: item,
            type: type,
            quantity: 1
          })
        });
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      }
      function increaseCartValue(item) {
        if (userData) {
          updateCart(item.product_id, userData.token, "add").then((resData) => {
            console.log(resData)
            if (resData.status == "success") {
              dispatch(addToCart(item))
            } else {
              // bottomsheet need out of stock
            }
          }).catch(error => {
            console.log(error);
          })
        }
      }
      function decreaseCartValue(item) {
        if (userData) {
          updateCart(item.product_id, userData.token, "decrease").then((resData) => {
            if (resData.status == "success") {
              dispatch(decreaseCart(item))
            } else {
              // bottomsheet need out of stock
            }
          })
        }
      }
      function removeCartValue(item) {
        if (userData) {
          updateCart(item.product_id, userData.token, "remove").then((resData) => {
            if (resData.status == "success") {
              dispatch(removeFromCart(item))
            } else {
              // bottomsheet need out of stock
            }
          })
        }
      }
    console.log(cartData.value)
    return (
        <div>
            {cartData.value.length > 0 ? <div class="cart-main-area mtb-50px">
                <div class="container">
                    <h3 class="cart-page-title">Your cart items</h3>
                    <div class="row">
                        <div class="col-lg-9 col-md-12 col-sm-12 col-12">
                            <div class="table-content table-responsive cart-table-content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Product Name</th>
                                            <th>Until Price</th>
                                            <th>Qty</th>
                                            <th>Subtotal</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartData.value.map((item) => {
                                            
                                            return <tr>
                                                <td class="product-thumbnail">
                                                    <a ><img class="img-responsive" src={process.env.REACT_APP_URL + item.thumbnail} alt="" /></a>
                                                </td>
                                                <td class="product-name"><a >{item.product_name}</a></td>
                                                <td class="product-price-cart"><span class="amount">₹{item.discounted_price}</span></td>
                                                <td class="product-quantity">
                                                    {item.is_available==="out_of_stock"?<div className='text-danger'>Out of Stock</div>:<div class="cart-plus-minus">
                                                        {item.quantity>1?<span className='cartMinus' onClick={()=>{decreaseCartValue(item)}}><i class="fa-solid fa-minus"></i></span>:<span className='cartMinus' onClick={()=>{removeCartValue(item)}}><i class="fa-solid fa-trash"></i></span>}
                                                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value={`${item.quantity}`} />
                                                        <span className='cartPlus' onClick={()=>{increaseCartValue(item)}}><i class="fa-solid fa-plus"></i></span>
                                                    </div>}
                                                </td>
                                                <td class="product-subtotal">₹{Number(item.discounted_price) * Number(item.quantity)}</td>
                                                <td class="product-remove">
                                                    <a onClick={()=>{removeCartValue(item)}}><i class="fa-solid fa-trash"></i></a>
                                                </td>
                                            </tr>
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            {/* <div class="row">
                                <div class="col-lg-4 col-md-6 mb-lm-30px">
                                    <div class="cart-tax">
                                        <div class="title-wrap">
                                            <h4 class="cart-bottom-title section-bg-gray">Estimate Shipping And Tax</h4>
                                        </div>
                                        <div class="tax-wrapper">
                                            <p>Enter your destination to get a shipping estimate.</p>
                                            <div class="tax-select-wrapper">
                                                <div class="tax-select">
                                                    <label>
                                                        * Country
                                                    </label>
                                                    <select class="email s-email s-wid">
                                                        <option>Bangladesh</option>
                                                        <option>Albania</option>
                                                        <option>Åland Islands</option>
                                                        <option>Afghanistan</option>
                                                        <option>Belgium</option>
                                                    </select>
                                                </div>
                                                <div class="tax-select">
                                                    <label>
                                                        * Region / State
                                                    </label>
                                                    <select class="email s-email s-wid">
                                                        <option>Bangladesh</option>
                                                        <option>Albania</option>
                                                        <option>Åland Islands</option>
                                                        <option>Afghanistan</option>
                                                        <option>Belgium</option>
                                                    </select>
                                                </div>
                                                <div class="tax-select mb-25px">
                                                    <label>
                                                        * Zip/Postal Code
                                                    </label>
                                                    <input type="text" />
                                                </div>
                                                <button class="cart-btn-2" type="submit">Get A Quote</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 mb-lm-30px">
                                    <div class="discount-code-wrapper">
                                        <div class="title-wrap">
                                            <h4 class="cart-bottom-title section-bg-gray">Use Coupon Code</h4>
                                        </div>
                                        <div class="discount-code">
                                            <p>Enter your coupon code if you have one.</p>
                                            <form>
                                                <input type="text" required="" name="name" />
                                                <button class="cart-btn-2" type="submit">Apply Coupon</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="col-lg-3 col-md-12 mt-md-30px">
                            <div class="grand-totall">
                                <div class="title-wrap">
                                    <h4 class="cart-bottom-title section-bg-gary-cart">Cart Total</h4>
                                </div>
                                <h5>Total products <span>₹{totalCartAmount.toFixed(2)}</span></h5>
                                <div class="total-shipping">
                                    <h5>Total tax</h5>
                                    <ul>
                                        {/* <li><input type="checkbox" /> Standard <span>$20.00</span></li> */}
                                        <li>GST <span>₹{totaltax.toFixed(2)}</span></li>
                                    </ul>
                                </div>
                                <div class="total-shipping mb-4">
                                    <h5>Total shipping</h5>
                                    <ul>
                                        {/* <li><input type="checkbox" /> Standard <span>$20.00</span></li> */}
                                        <li> Standard <span>₹100</span></li>
                                    </ul>
                                </div>
                                <h4 class="grand-totall-title">Grand Total <span>₹{(totalCartAmount+totaltax).toFixed(2)}</span></h4>
                                <Link to={"/checkout"}>Proceed to Checkout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : 
            <div className='d-flex align-items-center justify-content-center flex-column'>
                <img src={cartImg} />
                <h4 className='mb-5'>No product found!</h4>
            </div>
            }
        </div>
    )
}

export default Cart