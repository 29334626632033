import React from 'react'
import Slider from "react-slick";

function HotDeals() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
      };
    return (
        <div className="hot-deal-slider-wrapper slider-nav-style-1">
            <Slider {...settings}>
                <div className="slider-single-item">
                    {/* Single Item */}
                    <article className="list-product text-center">
                        <div className="product-inner">
                            <div className="img-block">
                                <a href="single-product.html" className="thumbnail">
                                    <img
                                        className="first-img"
                                        src="../images/product-image/4.jpg"
                                        alt=""
                                    />
                                    <img
                                        className="second-img"
                                        src="../images/product-image/5.jpg"
                                        alt=""
                                    />
                                </a>
                                <div className="add-to-link">
                                    <ul>
                                        <li>
                                            <a
                                                className="quick_view"
                                                href="#"
                                                data-link-action="quickview"
                                                title="Quick view"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <i className="lnr lnr-magnifier" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="wishlist.html" title="Add to Wishlist">
                                                <i className="lnr lnr-heart" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="compare.html" title="Add to compare">
                                                <i className="lnr lnr-sync" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul className="product-flag">
                                <li className="new">-12%</li>
                            </ul>
                            <div className="product-decs">
                                <a className="inner-link" href="shop-4-column.html">
                                    <span>STUDIO DESIGN</span>
                                </a>
                                <h2>
                                    <a href="single-product.html" className="product-link">
                                        TCL 49S5 49” 4K Ultra HD
                                    </a>
                                </h2>
                                <div className="pricing-meta">
                                    <ul>
                                        <li className="old-price">$21.51</li>
                                        <li className="current-price">$21.51</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="availability-list in-stock">
                                Availability: <span>300 In Stock</span>
                            </div>
                            <div className="clockdiv">
                                <div data-countdown="2023/12/01" />
                            </div>
                            <div className="cart-btn">
                                <a href="#" className="add-to-curt" title="Add to cart">
                                    Add to cart
                                </a>
                            </div>
                        </div>
                    </article>
                </div>
                <div className="slider-single-item">
                    {/* Single Item */}
                    <article className="list-product text-center">
                        <div className="product-inner">
                            <div className="img-block">
                                <a href="single-product.html" className="thumbnail">
                                    <img
                                        className="first-img"
                                        src="../images/product-image/6.jpg"
                                        alt=""
                                    />
                                    <img
                                        className="second-img"
                                        src="../images/product-image/7.jpg"
                                        alt=""
                                    />
                                </a>
                                <div className="add-to-link">
                                    <ul>
                                        <li>
                                            <a
                                                className="quick_view"
                                                href="#"
                                                data-link-action="quickview"
                                                title="Quick view"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <i className="lnr lnr-magnifier" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="wishlist.html" title="Add to Wishlist">
                                                <i className="lnr lnr-heart" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="compare.html" title="Add to compare">
                                                <i className="lnr lnr-sync" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul className="product-flag">
                                <li className="new">-12%</li>
                            </ul>
                            <div className="product-decs">
                                <a className="inner-link" href="shop-4-column.html">
                                    <span>STUDIO DESIGN</span>
                                </a>
                                <h2>
                                    <a href="single-product.html" className="product-link">
                                        Polk Audio T30 Speaker
                                    </a>
                                </h2>
                                <div className="pricing-meta">
                                    <ul>
                                        <li className="old-price">$21.51</li>
                                        <li className="current-price">$21.51</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="availability-list in-stock">
                                Availability: <span>300 In Stock</span>
                            </div>
                            <div className="clockdiv">
                                <div data-countdown="2023/12/01" />
                            </div>
                            <div className="cart-btn">
                                <a href="#" className="add-to-curt" title="Add to cart">
                                    Add to cart
                                </a>
                            </div>
                        </div>
                    </article>
                </div>
            </Slider>
        </div>
    )
}

export default HotDeals