import React, { useEffect } from 'react'
import aboutUsImg from "../../assets/gifs/Refund.gif"
function Cancelation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section class="about-area mtb-50px">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-left-image mb-md-30px mb-lm-30px ">
              <img src={aboutUsImg} alt="" class="img-responsive" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <div class="about-title">
                <h2>Cancelation & Return</h2>
              </div>
              <h5 class="mb-30px mt-30px">
                Faulty products are eligible for the return:
              </h5>
              <p class="mb-10px">
                Faulty products are eligible for the return
              </p>
              <p class="mb-10px">
                Products are returnable within the applicable return window.
              </p>
              <p class="mb-10px">
                Once the return is validated product has to be shipped proactively within 48 hours to get quick resolution.
              </p>
              <p class="mb-30px">
                If an eligible item is out of stock from the same seller, and if cannot be replaced. Only a refund against the returned item will be issued.
              </p>
              <p class="mb-10px">
                In the event the return of a Product is duly accepted by Seller, the value of such Product, as originally paid by Buyer during acceptance of Product, will either be refunded to Buyer either to the bank account provided by the Buyer for such refund, or to the payment instrument of the Buyer from which payment was made, or to any pre-paid payment instrument account of the Buyer. pc2u.in shall have the sole discretion to determine the mode of reversal from the above options.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <h5 class="mb-30px mt-30px">
              Cancellation of transaction / orders:
            </h5>
            <p>
              For order cancellation, click on View your order history, check the order &amp; select Problem with Order &amp; mail us choosing the subject line “Request for order cancellation”
            </p>
            <p>
              In any case if there are price discrepancies or listing error Seller cancels the order or requests pc2u.in to cancel the order.
            </p>
            <p>
              Only the requests through mail is considered for order cancellation.
            </p>
            <p>
              You can cancel an order until your order is in pending status.&nbsp;
            </p>
            <p>
              Orders which are dispatched &amp; in transit cannot be cancelled.
            </p>
            <p>
              On cancellation of your order a confirmation mail would be sent to your email.
            </p>
            <p className='mt-30px mt-30px'>
              pc2u.in shall have the sole discretion to determine the mode of reversal from the above options. In case a promotional code given by pc2u.in has been used for the purchase of the Product, the amount under such promotional code shall not be refunded if such of order has been cancelled. The above is the sole remedy of the Buyer and the sole liability of the Seller and sole responsibility of pc2u.in for any cancellation of the purchase order by the Seller or otherwise other than by the Buyer.
            </p>
          </div>
          <div className='col-md-6'>
            <h5 class="mb-30px mt-30px">
              Refunds:
            </h5>
            <p>On receipt of the product, return request is to be placed within 7 working days.</p>
            <p>Click on View your order History &amp; select the order you wish to return, then select the problem with product option &amp; message the seller with the apt subject line.</p>
            <p>Seller would contact you proactively within 48 hours &amp; check on the refund request &amp; respond back to you.</p>
            <p>Once the refund request is validated by the seller, product should be shipped back to the Seller.</p>
            <p>On receipt of the product &amp; ascertained, further process of refund is initiated.</p>
            <p>In any case, if the resolution given by Seller is not apt or as per the policy then buyer can ask Shield team to address &amp; resolve unprejudiced.</p>
            <p>On order cancellation refund would depend on the mode of payment chosen by you the refund would be initiated within 3 to 5 working days.</p>

          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center mt-60px cancellationTable'>
          <table>
            <tbody>
              <tr>
                <td><strong>Payment mode</strong></td>
                <td><strong>Refund mode options</strong></td>
              </tr>
              <tr>
                <td>Credit card/ Debit card</td>
                <td>Credit card/ Debit card</td>
              </tr>
              <tr>
                <td>IMPS/Bank Account through net banking</td>
                <td>IMPS/Bank account through net banking</td>
              </tr>
              <tr>
                <td>UPI</td>
                <td>UPI</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default Cancelation