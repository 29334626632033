import React from 'react'
import Lottie from "lottie-react";
import success from "../assets/lottie/success.json"
import wentWrong from "../assets/lottie/wentwrong.json"

function GlobalAlert({ alert, setAlert }) {
    return (
        <div className='container-fluid mt-2'>
            {alert.alertType == "alert" ? <div class={`alert alert-${alert.type} alert-dismissible d-flex align-items-center fade ${alert.open ? "show" : "d-none"}`} role="alert">
                <div className='' Style="height: 40px ; width: 40px">
                    <Lottie animationData={alert.type == "success" ? success : wentWrong} loop={true} />
                </div>
                <h6 className='mb-0 ms-3'>{alert.msg}</h6>
                <button type="button" class="btn-close" onClick={() => { setAlert({ ...alert, open: false }) }} aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                </button>
            </div> :
                <div class={`modal fade ${alert.open ? "show" : ""} `} id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" Style="padding-top: 40px; padding-bottom: 40px;">
                        <div class="modal-content">
                            <div class="modal-body d-flex align-items-center justify-content-center">
                                <div className='' Style="height: 40px ; width: 40px">
                                    <Lottie animationData={alert.type == "success" ? success : wentWrong} loop={true} />
                                </div>
                                <h6 className='mb-0 ms-3'>{alert.msg}</h6>
                            </div>
                            <div class="modal-footer justify-content-center">
                                <button type="button" class="btn btn-secondary w-75" onClick={()=>{setAlert({...alert, open: false})}}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default GlobalAlert