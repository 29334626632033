import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";


function FeaturedProducts({ popularProduct, addToCartFunc }) {
    var settings = {
        infinite: true,
        arrows: true,
        // prevArrow: '<span class="prev"><i class="ion-ios-arrow-left"></i></span>',
        // nextArrow: '<span class="next"><i class="ion-ios-arrow-right"></i></span>',
        speed: 800,
        easing: 'linear',
        useCSS: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        easing: 'linear',
        dots: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }
        ]
    };
    // console.log(popularProduct)
    return (
        <div className="feature-slider-wrapper slider-nav-style-1">
            <Slider {...settings} className='featureProd'>
                {popularProduct.map((item, index) => {
                    return index % 2 == 0 ? <div key={index} className="popular-product-card">
                        {/* Single Item */}
                        <div className="list-product text-center mb-30px">
                            <div className="product-inner">
                                <div className="img-block">
                                    <Link to={`/product/${item.product_id}`} className="thumbnail">
                                        <img
                                            className="popular-product-img first-img"
                                            src={process.env.REACT_APP_URL + item.thumbnail}
                                            alt={item.product_name}
                                        />
                                        <img
                                            className="popular-product-img second-img"
                                            src={process.env.REACT_APP_URL + item.thumbnail}
                                            alt={item.product_name}
                                        />
                                    </Link>
                                    <div className="add-to-link">
                                        <ul>
                                            <li>
                                                <a href="wishlist.html" title="Add to Wishlist">
                                                    <i className="lnr lnr-heart" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <ul className="product-flag">
                                    <li className="new">-{item.discount}%</li>
                                </ul>
                                <div className="product-decs">
                                    <a className="inner-link" href="shop-4-column.html">
                                        <span>{item.category_name}</span>
                                    </a>
                                    <h2>
                                        <Link to={`/product/${item.product_id}`} className="product-link">
                                            {item.product_name.length>65?item.product_name.slice(0,64)+"...":item.product_name}
                                        </Link>
                                    </h2>
                                    <div className="pricing-meta">
                                        <ul>
                                            <li className="old-price">₹{Number(item.price).toFixed(2)}</li>
                                            <li className="current-price">₹{(Number(item.price) - (Number(item.discount) * Number(item.price) / 100)).toFixed(2)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="cart-btn">
                                    {<a onClick={()=>{addToCartFunc(item)}} className="add-to-curt" title="Add to cart">
                                        Add to cart
                                    </a>}
                                </div>
                            </div>
                        </div>
                    </div> : ""
                })}
            </Slider>
            <Slider {...settings} className='featureProd'>
                {popularProduct.map((item, index) => {
                    return index % 2 != 0 ? <div key={index} className="popular-product-card">
                        {/* Single Item */}
                        <div className="list-product text-center mb-30px">
                            <div className="product-inner">
                                <div className="img-block">
                                    <Link to={`/product/${item.product_id}`} className="thumbnail">
                                        <img
                                            className="popular-product-img first-img"
                                            src={process.env.REACT_APP_URL + item.thumbnail}
                                            alt={item.product_name}
                                        />
                                        <img
                                            className="popular-product-img second-img"
                                            src={process.env.REACT_APP_URL + item.thumbnail}
                                            alt={item.product_name}
                                        />
                                    </Link>
                                    <div className="add-to-link">
                                        <ul>
                                            <li>
                                                <a href="wishlist.html" title="Add to Wishlist">
                                                    <i className="lnr lnr-heart" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <ul className="product-flag">
                                    <li className="new">-{item.discount}%</li>
                                </ul>
                                <div className="product-decs">
                                    <a className="inner-link" href="shop-4-column.html">
                                        <span>{item.category_name}</span>
                                    </a>
                                    <h2>
                                        <Link to={`/product/${item.product_id}`} className="product-link">
                                            {item.product_name.length>65?item.product_name.slice(0,64)+"...":item.product_name}
                                        </Link>
                                    </h2>
                                    <div className="pricing-meta">
                                        <ul>
                                            <li className="old-price">₹{Number(item.price).toFixed(2)}</li>
                                            <li className="current-price">₹{(Number(item.price) - (Number(item.discount) * Number(item.price) / 100)).toFixed(2)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="cart-btn">
                                    <a onClick={()=>{addToCartFunc(item)}} className="add-to-curt" title="Add to cart">
                                        Add to cart
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> : ""
                })}
            </Slider>
        </div>
    )
}

export default FeaturedProducts