import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';
import { getcartAllData } from '../../services/CartSlice';
import useGetAddress from '../../hooks/useGetAddress';
import ReactSearchBox from "react-search-box";
import Select from 'react-select';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51OrAw0SDWBpGH7Ry6daf1iybbjUSOXprzp5oN7MZAj8Edhl6VJm5slf8YCaP6pmkghgD05fRBy33GyFjE6nPXSUV00GrvJS10i');

function Checkout({alert, setAlert, setorderId, orderId}) {
    const { countryList, error } = useGetAddress("country")
    const cartData = useSelector((state) => (state.counter))
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [totalCartAmount, setTotalCartAmount] = useState(0)
    const [totaltax, settotaltax] = useState(0)
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))
    const [loading, setLoading] = useState(true)
    const [shippingAddress, setShippingAddress] = useState({
        fname: JSON.parse(localStorage.getItem("userData")).first_name,
        lname: JSON.parse(localStorage.getItem("userData")).last_name,
        phone: JSON.parse(localStorage.getItem("userData")).mobile,
        email: JSON.parse(localStorage.getItem("userData")).email,
        address: {
            city: "",
            line1: "",
            line2: "",
            postalCode: "",
            country: "",
            state: ""
        }
    })

    const [stateList, setStateList] = useState([])
    const [countryData, setcountryData] = useState()
    const [stateData, setstateData] = useState()
    const [fromError, setFromError] = useState({
        type: "",
        msg: ""
    })
    const [checkoutCart, setCheckoutCart] = useState([])

    const Navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        var totalValue = 0;
        var totalTaxValue = 0
        var checkoutCartArr=[]
        cartData.value.forEach(item => {
            checkoutCartArr.push({ product_id: item.product_id, count: Number(item.quantity), referral_id: item.referral_id })
            totalValue = totalValue + (Number(item.discounted_price) * Number(item.quantity))
            totalTaxValue = totalTaxValue + (Number(item.tax) * Number(item.quantity))
        });
        setTotalCartAmount(totalValue)
        settotaltax(totalTaxValue)
        setCheckoutCart(checkoutCartArr)
    }, [cartData.value])

    useEffect(() => {
        document.getElementsByClassName("header-wrapper")[0].style.display = "block"
        document.getElementsByClassName("footer-container")[0].style.display = "block"
    }, [])

    async function checkout() {
        const response = await fetch(`${process.env.REACT_APP_URL}order_api/order/checkout`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                products: checkoutCart,
                shipping_address: {
                    address1: shippingAddress?.address.line1,
                    address2: shippingAddress?.address.line2,
                    city: shippingAddress?.address.city,
                    postal_code: shippingAddress?.address.postalCode,
                    country: shippingAddress?.address.country,
                    state: shippingAddress?.address.state,
                    phone: shippingAddress?.phone,
                    name: shippingAddress?.fname+" "+shippingAddress?.lname,
                    type: "home"
                },
                billing_address: {
                    address1: shippingAddress?.address.line1,
                    address2: shippingAddress?.address.line2,
                    city: shippingAddress?.address.city,
                    postal_code: shippingAddress?.address.postalCode,
                    country: shippingAddress?.address.country,
                    state: shippingAddress?.address.state,
                    phone: shippingAddress?.phone,
                    name: shippingAddress?.fname+" "+shippingAddress?.lname,
                    type: "home"
                },
                token: JSON.parse(localStorage.getItem("userData")).token
            })
        });
        return response.json();
    }

    function checkOutCart() {
        console.log(shippingAddress)
        if (shippingAddress.fname == "") {
            document.getElementById("fname").focus()
            setFromError({
                type: "fname",
                msg: "Please enter your first name."
            })
        }
        else if (shippingAddress.lname == "") {
            document.getElementById("lname").focus()
            setFromError({
                type: "lname",
                msg: "Please enter your last name."
            })
        }
        else if (shippingAddress.address.line1 == "") {
            document.getElementById("line1").focus()
            setFromError({
                type: "line1",
                msg: "Please enter your address 1."
            })
        }
        else if (shippingAddress.address.line2 == "") {
            document.getElementById("line2").focus()
            setFromError({
                type: "line2",
                msg: "Please enter your address 2."
            })
        }
        else if (shippingAddress.address.country == "") {
            document.getElementById("country").focus()
            setFromError({
                type: "country",
                msg: "Please enter your country."
            })
        }
        else if (shippingAddress.address.city == "") {
            document.getElementById("city").focus()
            setFromError({
                type: "city",
                msg: "Please enter your city."
            })
        }
        else if (shippingAddress.address.state == "") {
            document.getElementById("state").focus()
            setFromError({
                type: "state",
                msg: "Please enter your state."
            })

        }
        else if (shippingAddress.address.postalCode == "") {
            document.getElementById("postalcode").focus()
            setFromError({
                type: "postalcode",
                msg: "Please enter your postal code."
            })
        }
        else if (shippingAddress.phone == "") {
            document.getElementById("phonenumber").focus()
            setFromError({
                type: "phone",
                msg: "Please enter your phone."
            })
        }
        else if (shippingAddress.email == "") {
            document.getElementById("email").focus()
            setFromError({
                type: "email",
                msg: "Please enter your email."
            })
        }
        else {
            checkout().then((checkoutdata) => {
                try {
                    if (checkoutdata.status == "success") {
                        console.log(checkoutdata)
                        setorderId(checkoutdata.checkoutid)
                        createStripeIntent().then((data) => {
                            // initializePaymentSheet(data.paymentIntent, data.publishableKey, data.customer, data.ephemeralKey).then((paymentdata) => {
                            //     openPaymentSheet(data.allPaymentIntent.id, checkoutdata.checkoutid)
                            // })
                            console.log(data)
                            Navigate("/payment/" + data.allPaymentIntent.client_secret+"/"+checkoutdata.checkoutid)

                        })
                    }
                    else{
                        setAlert({...alert, open: true, type: "danger", alertType: "modal", msg: checkoutdata.message})
                    }
                } catch (error) {

                }
            })
        }
    }

    async function createStripeIntent() {
        const response = await fetch(`${process.env.REACT_APP_URL}order_api/paymentintent/paymentIntentCreate`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                amount: totalCartAmount.toFixed(2) * 100,
                name: shippingAddress.fname + " " + shippingAddress.lname,
                phone: shippingAddress.phone,
                email: shippingAddress.email,
                address: {
                    city: shippingAddress.address.city,
                    line1: shippingAddress.address.line1,
                    line2: shippingAddress.address.line2,
                    postalCode: shippingAddress.address.postalCode,
                    country: shippingAddress.address.country,
                    state: shippingAddress.address.state
                }
            })
        });
        return response.json();

    }

    useEffect(() => {
        if (localStorage.getItem("userData")) {
            dispatch(getcartAllData(JSON.parse(localStorage.getItem("userData")).token))
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (shippingAddress.address.country) {
            callState()
        }
    }, [shippingAddress.address.country])

    async function fetchStateData() {
        const response = await fetch(`${process.env.REACT_APP_URL}country_api/country/getState`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                countryname: shippingAddress.address.country
            })
        })
        return response.json()
    }

    function callState() {
        fetchStateData().then((data) => {
            var allStateList = [];
            data.all_states.forEach(element => {
                allStateList.push({ label: element.name, value: element.name })
            });
            console.log(allStateList)
            setStateList(allStateList)
        })
    }


    const handleChange = (selectedOption) => {
        setShippingAddress({ ...shippingAddress, address: { ...shippingAddress.address, country: selectedOption.value } })
        setcountryData(selectedOption)
    }

    const handleStateChange = (selectedOption) => {
        setShippingAddress({ ...shippingAddress, address: { ...shippingAddress.address, state: selectedOption.value } })
        setstateData(selectedOption)
    }

    return (

        <div class="checkout-area mt-50px mb-40px">
            {cartData.value ? <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="billing-info-wrap">
                            <h3>Shipping Details</h3>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20px">
                                        <label>First Name</label>
                                        <input id='fname' value={shippingAddress.fname} type="text" onChange={(e) => { setShippingAddress({ ...shippingAddress, fname: e.target.value }) }} />
                                        {fromError.type == "fname" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20px">
                                        <label>Last Name</label>
                                        <input id='lname' value={shippingAddress.lname} type="text" onChange={(e) => { setShippingAddress({ ...shippingAddress, lname: e.target.value }) }} />
                                        {fromError.type == "lname" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-info mb-20px">
                                        <label>Address</label>
                                        <input id='line1' class="billing-address" value={shippingAddress.address.line1} placeholder="House number and street name" type="text" onChange={(e) => { setShippingAddress({ ...shippingAddress, address: { ...shippingAddress.address, line1: e.target.value } }) }} />
                                        {fromError.type == "line1" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                        <input id='line2' placeholder="Apartment, suite, unit etc." type="text" value={shippingAddress.address.line2} onChange={(e) => { setShippingAddress({ ...shippingAddress, address: { ...shippingAddress.address, line2: e.target.value } }) }} />
                                        {fromError.type == "line2" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-select mb-20px">
                                        <label>Country</label>
                                        {/* <select>
                                            <option>Select a country</option>
                                            <option>Azerbaijan</option>
                                            <option>Bahamas</option>
                                            <option>Bahrain</option>
                                            <option>Bangladesh</option>
                                            <option>Barbados</option>
                                        </select> */}
                                        <Select
                                            value={countryData}
                                            onChange={handleChange}
                                            options={countryList}
                                            id='country'
                                        />
                                        {fromError.type == "country" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="billing-info mb-20px">
                                        <label>Town / City</label>
                                        <input id='city' type="text" value={shippingAddress.address.city} onChange={(e) => { setShippingAddress({ ...shippingAddress, address: { ...shippingAddress.address, city: e.target.value } }) }} />
                                        {fromError.type == "city" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20px">
                                        <label>State / County</label>
                                        <Select
                                            value={stateData}
                                            onChange={handleStateChange}
                                            options={stateList}
                                            id='state'
                                        />
                                        {fromError.type == "state" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20px">
                                        <label>Postcode / ZIP</label>
                                        <input id='postalcode' type="text" value={shippingAddress.address.postalCode} onChange={(e) => { setShippingAddress({ ...shippingAddress, address: { ...shippingAddress.address, postalCode: e.target.value } }) }} />
                                        {fromError.type == "postalcode" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20px">
                                        <label>Phone</label>
                                        <input id='phonenumber' value={shippingAddress.phone} type="text" onChange={(e) => { setShippingAddress({ ...shippingAddress, phone: e.target.value }) }} />
                                        {fromError.type == "phone" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="billing-info mb-20px">
                                        <label>Email Address</label>
                                        <input id='email' value={shippingAddress.email} type="text" onChange={(e) => { setShippingAddress({ ...shippingAddress, email: e.target.value }) }} />
                                        {fromError.type == "email" ? <span className='text-danger mb-3 mt-1'>{fromError.msg}</span> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 mt-md-30px mt-lm-30px ">
                        <div class="your-order-area">
                            <h3>Your order</h3>
                            <div class="your-order-wrap gray-bg-4">
                                <div class="your-order-product-info">
                                    <div class="your-order-top mt-3">
                                        <ul>
                                            <li>Product</li>
                                            <li>Total</li>
                                        </ul>
                                    </div>
                                    <div class="your-order-middle">
                                        <ul>
                                            {cartData.value.map((item) => {
                                                return <li><span class="order-middle-left">{item.product_name.length > 24 ? item.product_name.slice(0, 21) + "..." : item.product_name} X {item.quantity}</span> <span class="order-price">₹{(Number(item.discounted_price) * Number(item.quantity)).toFixed(2)} </span></li>
                                            })}
                                        </ul>
                                    </div>
                                    <div class="your-order-bottom">
                                        <ul>
                                            <li class="your-order-shipping">Shipping</li>
                                            <li>Free shipping</li>
                                        </ul>
                                    </div>
                                    <div class="your-order-bottom">
                                        <ul>
                                            <li class="your-order-shipping">Tax</li>
                                            <li>₹{totaltax.toFixed(2)}</li>
                                        </ul>
                                    </div>
                                    <div class="your-order-total">
                                        <ul>
                                            <li class="order-total">Total</li>
                                            <li>₹{totalCartAmount.toFixed(2)}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="Place-order mt-25">
                                <a class="btn-hover cursor-pointer" onClick={checkOutCart}>Place Order</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div>Loading</div>}
        </div>
    )
}

export default Checkout