import { React, useState, useEffect } from 'react'

const useProductFetch = (product_id) => {
    const [prodDetails, setProdDetails] = useState([]);
    const [prodDetLoading, setProdDetloading] = useState(true);
    const [productReviews, setProductReviews] = useState([])
    const [error, setError] = useState(false);

    async function fetchProdDetailsData() {
        const response = await fetch(`${process.env.REACT_APP_URL}product_api/product/getProductDetails`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "Post",
            body: JSON.stringify({
                product_id: product_id
            })
        })
        return response.json()
    }

    async function fetchProReviewData() {
        const response = await fetch(`${process.env.REACT_APP_URL}product_api/product/getParticularProductReview`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "Post",
            body: JSON.stringify({
                product_id: product_id
            })
        })
        return response.json()
    }


    const fetchData = () => {
        setProdDetloading(true);
        try {
            fetchProdDetailsData().then((data) => {
                if(data.status=="success"){
                    setProdDetails(data.product_details)
                }
            })
            fetchProReviewData().then((data)=>{
                if(data.status=="success"){
                    setProductReviews(data.allReview)
                    console.log(data.allReview)
                    setTimeout(() => {
                        setProdDetloading(false);
                    }, 200);
                }
            })
        } catch (error) {
            setError(error)
        } finally {
            // setProdDetloading(false);
        }
    }

    useEffect(() => {
        fetchData()
        //   console.log(products)
    }, [])


    return { prodDetails, prodDetLoading, error, productReviews };
}

export default useProductFetch