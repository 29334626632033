import React,{useEffect} from 'react'
import termsImg from "../../assets/gifs/accept-terms.gif"
function TermsandCondition() {
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])
    return (
        <section class="about-area mtb-50px">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-left-image mb-md-30px mb-lm-30px ">
                            <img src={termsImg} alt="" class="img-responsive w-100" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content">
                            <div class="about-title">
                                <h2>Terms & Condition</h2>
                            </div>
                            <p class="mb-30px">
                                Welcome to pc2u.in! These Terms of Service outline the rules and regulations for the use of our website.
                            </p>
                            <p class="mb-30px">
                                By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use pc2u.in's website if you do not accept all of the terms and conditions stated on this page.
                            </p>
                            <p class="mb-30px">
                                The following terminology applies to these Terms of Service, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                            </p>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <h5 class="mb-30px mt-30px">
                            Cookies:
                        </h5>
                        <p>
                            We employ the use of cookies. By accessing pc2u.in, you agreed to use cookies in agreement with the pc2u.in's Privacy Policy.
                        </p>
                        <p>
                            Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies
                        </p>
                        <h5 class="mb-30px">
                            License:
                        </h5>
                        <p>
                            Unless otherwise stated, pc2u.in and/or its licensors own the intellectual property rights for all material on pc2u.in. All intellectual property rights are reserved. You may access this from pc2u.in for your own personal use subjected to restrictions set in these terms and conditions.
                        </p>
                    </div>
                    <div className='col-md-6'>
                        <h5 class="mb-30px mt-30px">
                            You warrant and represent that:
                        </h5>
                        <p>
                            You are entitled to post the Comments on our website and have all necessary licenses and consents to do so.
                        </p>
                        <p>
                            The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party.
                        </p>
                        <p>
                            The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy.
                        </p>
                        <p>
                            he Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.
                        </p>
                        <p>
                            You hereby grant pc2u.in a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
                        </p>
                    </div>
                    <div className='col-md-4'>
                        <h5 class="mb-30px mt-30px">
                            Reservation of Rights:
                        </h5>
                        <p>
                            We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                        </p>
                    </div>
                    <div className='col-md-4'>
                        <h5 class="mb-30px mt-30px">
                            Removal of links from our website:
                        </h5>
                        <p>
                            If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
                        </p>
                        <p>
                            We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                        </p>
                    </div>
                    <div className='col-md-4'>
                        <h5 class="mb-30px mt-30px">
                            Disclaimer:
                        </h5>
                        <p>
                            To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TermsandCondition