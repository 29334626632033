import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux';

export const getcartAllData= createAsyncThunk("cartData", async (mytoken)=>{
 const response= await fetch(`${process.env.REACT_APP_URL}cart_api/cart/getCartItem`,{
    headers :{
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      token:  mytoken

    })
 });

 return response.json();
})

export const getWishlistItem= createAsyncThunk("wishListData", async (mytoken)=>{
 const response= await fetch(`${process.env.REACT_APP_URL}cart_api/wishlist/getWishlistItem`,{
    headers :{
      "Content-Type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      token:  mytoken

    })
 });

 return response.json();
})

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: [],
    withoutLoginValue:[],
    wishlist:[],
    loading: false,
    wishlistLoading:false,
    cartLoading:false,
    prodType: "Physical",
    error: null
  },
   reducers: {
    addToCart: (state, action) => {
      state.cartLoading=true
      if (state.value.length > 0) {
        var cart = state.value;
        var detectIncrease=false;
        cart.forEach((item, index) => {
          console.log(item.product_id)
          if (item.product_id == action.payload.product_id) {
            state.value[index].quantity = Number(cart[index].quantity)+1;
            detectIncrease=true
          } else if(item.product_id != action.payload.product_id && index==cart.length-1 && !detectIncrease ) {
            state.value.push({ ...action.payload, quantity: 1, referral_id: action.payload.referral_id })
          }
        })
      } else {
        state.value = [{ ...action.payload, quantity: 1, referral_id: action.payload.referral_id }]
      }
      state.cartLoading=false
    },
    addToCartWithoutLogin: (state, action) => {
      if (state.withoutLoginValue.length > 0) {
        var cart = state.withoutLoginValue;
        cart.forEach((item, index) => {
          if (item.product_id == action.payload.product_id) {
            cart[index].quantity = Number(cart[index].quantity)+1;
          } else if(item.product_id != action.payload.product_id && index==cart.length-1) {
            cart.push({ ...action.payload, quantity: 1, referral_id: action.payload.referral_id })
          }
        })
        state.withoutLoginValue = cart;
      } else {
        state.withoutLoginValue = [{ ...action.payload, quantity: 1, referral_id: action.payload.referral_id }]
      }
    },
    decreaseCart: (state, action) => {
      state.cartLoading=true
      if (state.value.length > 0) {
        var cart = state.value;
        cart.forEach((item, index) => {
          if (item.product_id == action.payload.product_id) {
            if (Number(item.quantity) > 1) {
              cart[index].quantity = Number(cart[index].quantity)-1
            } else {
              cart = state.value.filter((cartItem) => {
                return cartItem.product_id != action.payload.product_id
              })
            }
          }
        })
        state.value = cart;
      }
      state.cartLoading=false
    },
    removeFromCart: (state, action) => {
      if (state.value.length > 0) {
        var cart = state.value;
        cart = state.value.filter((cartItem) => {
          return cartItem.product_id != action.payload.product_id
        })
        state.value = cart;
      }
    },
    removeAllCart: (state, action) => {
      if (state.value.length > 0) {
        state.value = action.payload
      }
    },
    addWishlist: (state, action)=>{
      if (state.wishlist.length > 0) {
        var cart = state.wishlist;
        var detectIncrease=false;
        cart.forEach((item, index) => {
          // console.log(item.product_id)
          if (item.product_id == action.payload.product_id) {
            state.wishlist[index].quantity = Number(cart[index].quantity)+1;
            detectIncrease=true
          } else if(item.product_id != action.payload.product_id && index==cart.length-1 && !detectIncrease ) {
            state.wishlist.push({ ...action.payload, quantity: 1, referral_id: action.payload.referral_id })
          }
        })
      } else {
        state.wishlist = [{ ...action.payload, quantity: 1, referral_id: action.payload.referral_id }]
      }
    },
    removeWishlist: (state, action)=>{
      if (state.wishlist.length > 0) {
        var cart = state.wishlist;
        cart = state.wishlist.filter((cartItem) => {
          return cartItem.product_id != action.payload.product_id
        })
        state.wishlist = cart;
      }
    },
    changeProdType: (state, action)=>{
      state.prodType=action.payload
    },
    removeWithoutLoginCart: (state, action)=>{
      state.withoutLoginValue=[]
    }
  },
  extraReducers: (builder)=>{
    builder.addCase(getcartAllData.pending, (state)=>{
      state.loading= true
    });
    builder.addCase(getcartAllData.fulfilled, (state, action)=>{
      state.value= action.payload?[...action.payload.cart_items]: state.value
      state.withoutLoginValue=[];
      state.loading= false
      // console.log(action.payload)
    });
    builder.addCase(getcartAllData.rejected, (state, action)=>{
      state.error= action.payload
      state.loading= false
      // console.log(state)
    });
    builder.addCase(getWishlistItem.pending, (state)=>{
      state.wishlistLoading= true
    });
    builder.addCase(getWishlistItem.fulfilled, (state, action)=>{
      state.wishlist= action.payload?[...action.payload.wishlist_items]: state.wishlist
      state.withoutLoginValue=[];
      state.wishlistLoading= false
      // console.log(action.payload)
    });
    builder.addCase(getWishlistItem.rejected, (state, action)=>{
      state.error= action.payload
      state.wishlistLoading= false
      // console.log(state)
    });
  },
})

// Action creators are generated for each case reducer function
export const { addToCart, removeFromCart, decreaseCart, addToCartWithoutLogin, removeAllCart, addWishlist, removeWishlist, changeProdType, removeWithoutLoginCart  } = counterSlice.actions
export default counterSlice.reducer