import { React, useState, useEffect } from 'react'

const useGetAddress = (type, countryName) => {
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    async function fetchCountryList() {
        const response = await fetch(`${process.env.REACT_APP_URL}country_api/country/getCountry`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }

    async function fetchStateData() {
        const response = await fetch(`${process.env.REACT_APP_URL}country_api/country/getState`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                countryname: countryName?countryName:""
            })
        })
        return response.json()
    }


    const fetchData = () => {
        setLoading(true);
        if (type == "country") {

            try {
                fetchCountryList().then((data) => {
                    var allCountryList = [];
                    data.all_country.forEach(element => {
                        allCountryList.push({ label: element.name, value: element.name })
                    });
                    // console.log(data)
                    setCountryList(allCountryList)
                    setLoading(false)
                })
            } catch (error) {
                setLoading(false)
                setError(error)
            }
        } else if (type == "state") {
            try {
                fetchStateData().then((data) => {
                    var allStateList = [];
                    data.all_states.forEach(element => {
                        allStateList.push({ label: element.name, value: element.name })
                    });
                    // console.log(data)
                    setStateList(allStateList)
                    setLoading(false)
                })
            } catch (error) {
                setError(error)
            }

        }
    }

    useEffect(() => {
        fetchData()
    }, [type])


    return { countryList, loading, stateList, error };
}

export default useGetAddress