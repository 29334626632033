import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function Header({ allCategory }) {

    const [searchResultBar, setSearchResultBar] = useState(false)

    const cartData = useSelector((state) => (state.counter))

    function searchItem(text) {
        setSearchResultBar(true)
    }
    // console.log(cartData)

    return (
        <>
            <header className="header-wrapper">
                {/* Header Nav End */}
                <div className="header-top bg-white ptb-10px d-lg-block d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-flex">
                                <div className="logo align-self-center">
                                    <Link to={"/"}>
                                        <img
                                            className="img-responsive"
                                            src={process.env.REACT_APP_MAIN_URL + "/images/logo/logo.png"}
                                            alt="logo.jpg"
                                            Style="width: 150px;"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-9 align-self-center">
                                <div className="header-right-element d-flex">
                                    <div className="search-element media-body mr-20px dropdown">
                                        <form className="d-flex">
                                            <input type="text" placeholder="Enter your search key ... " onChange={(e) => { searchItem(e.target.value) }} />
                                            <button>Search</button>
                                        </form>
                                        <ul class="dropdown-menu" Style={`display: ${searchResultBar?"flex":"none"}; flex-direction: column; width: 100%; margin-top: 1% !important;`}>
                                            <li><a class="dropdown-item" href="#">Action</a></li>
                                            <li><a class="dropdown-item" href="#">Another action</a></li>
                                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div>
                                    {/*Cart info Start */}
                                    {localStorage.getItem("userData") ?
                                        <div className="header-tools d-flex">
                                            <div className="cart-info d-flex align-self-center">
                                                {/* <Link
                                                    to={"/wishlist"}
                                                    className="heart offcanvas-toggle"
                                                >
                                                    <div className='cartWishlist'>3</div>
                                                    <i className="lnr lnr-heart" />
                                                    <span>Wishlist</span>
                                                </Link> */}
                                                <Link to={"/cart"} id="navCart" className="bag">
                                                    <div className='cartWishlist'>{cartData.value.length}</div>
                                                    <i className="lnr lnr-cart" />
                                                    <span>My Cart</span>
                                                </Link>
                                            </div>

                                        </div>
                                        :
                                        <div className="header-tools d-flex">
                                            <div className=" d-flex align-self-center">
                                                <Link to={"/login"} type="button" class="button-30 me-2" Style="    padding: 15px;">Log In</Link>
                                                <Link to={"/register"} type="button" class="button-29" Style="    padding: 15px;">Sign Up</Link>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/*Cart info End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Header Nav End */}
                <div className="header-menu bg-white sticky-nav d-lg-block d-none padding-0px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="header-menu-vertical">
                                    <h4 className="menu-title" id="categoryBtn">Browse Categories </h4>
                                    <ul className="menu-content display-none">
                                        {allCategory.length > 0 ?
                                            allCategory.map((item, index) => {
                                                return <li key={index}><Link to={`product/category/${item.id}`} className="menu-item d-flex">
                                                    <img src={process.env.REACT_APP_URL + item.category_image} className='categoryImage ' /><p className='categoryItem mb-0'>{item.category_name}</p>
                                                </Link>
                                                </li>
                                            })
                                            :
                                            <div>No Category Found!</div>
                                        }
                                    </ul>
                                    {/* <ul className="menu-content display-none">
                                        <li className="menu-item">
                                            <a href="#">Televisions</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">
                                                Electronics <i className="ion-ios-arrow-right" />
                                            </a>
                                            <ul className="sub-menu flex-wrap">
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            {" "}
                                                            <strong> Accessories &amp; Parts</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Cables &amp; Adapters</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Batteries</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Chargers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Bags &amp; Cases</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Electronic Cigarettes</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            <strong>Camera &amp; Photo</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Digital Cameras</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Camcorders</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Camera Drones</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Action Cameras</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Photo Studio Supplie</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            <strong>Smart Electronics</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Wearable Devices</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Home Appliances</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Remote Controls</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Watches</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Wristbands</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            <strong>Audio &amp; Video</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Televisions</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">TV Receivers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Projectors</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Audio Amplifier Boards</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">TV Sticks</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">
                                                Video Games <i className="ion-ios-arrow-right" />
                                            </a>
                                            <ul className="sub-menu sub-menu-2">
                                                <li>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Handheld Game Players</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Game Controllers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Joysticks</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Stickers</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">Digital Cameras</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">Headphones</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Wearable Devices</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Smart Watches</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Game Controllers</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Smart Home Appliances</a>
                                        </li>
                                    </ul> */}
                                    {/* menu content */}
                                </div>
                                {/* header menu vertical */}
                            </div>
                            <div className="col-lg-6">
                                <div className="header-horizontal-menu">
                                    <ul className="menu-content">
                                        <li>
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="menu-dropdown">
                                            <a>
                                                Shop <i className="ion-ios-arrow-down" />
                                            </a>
                                            <ul className="mega-menu-wrap">
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop Grid</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-3-column.html">Shop Grid 3 Column</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-4-column.html">Shop Grid 4 Column</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-left-sidebar.html">
                                                                Shop Grid Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-right-sidebar.html">
                                                                Shop Grid Right Sidebar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop List</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-list.html">Shop List</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-list-left-sidebar.html">
                                                                Shop List Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-list-right-sidebar.html">
                                                                Shop List Right Sidebar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop Single</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product.html">Shop Single</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-variable.html">
                                                                Shop Variable
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-affiliate.html">
                                                                Shop Affiliate
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-group.html">Shop Group</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-tabstyle-2.html">
                                                                Shop Tab 2
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-tabstyle-3.html">
                                                                Shop Tab 3
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop Single</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-slider.html">Shop Slider</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-gallery-left.html">
                                                                Shop Gallery Left
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-gallery-right.html">
                                                                Shop Gallery Right
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-sticky-left.html">
                                                                Shop Sticky Left
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-sticky-right.html">
                                                                Shop Sticky Right
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="w-100">
                                                    <ul className="banner-megamenu-wrapper d-flex">
                                                        <li className="banner-wrapper mr-30px">
                                                            <a href="single-product.html">
                                                                <img
                                                                    src="../images/menu-image/banner-menu2.jpg"
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </li>
                                                        <li className="banner-wrapper">
                                                            <a href="single-product.html">
                                                                <img
                                                                    src="../images/menu-image/banner-menu3.jpg"
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to={"/aboutus"}>About Us</Link>
                                        </li>
                                        <li>
                                            <Link to={"/contactus"}>Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3 d-flex justify-content-start align-items-center' >
                                {localStorage.getItem("userData") ?
                                    <>
                                        <div className="header-tools header-tools-sticky d-none d-flex h-100 justify-content-center">
                                            <div className="cart-info d-flex align-self-center">
                                                {/* <a
                                                    href="#offcanvas-wishlist"
                                                    className="heart offcanvas-toggle"
                                                >
                                                    <i className="lnr lnr-heart" />
                                                    <span>Wishlist</span>
                                                </a> */}
                                                <Link to={"/cart"} id="navCart" className="bag">
                                                    <div className='cartWishlist'>{cartData.value.length}</div>
                                                    <i className="lnr lnr-cart" />
                                                    <span>My Cart</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center ms-3'>
                                            <div className='account'>
                                                <div class="dropdown">
                                                    <div class=" dropdown-toggle dropDownBtn" Style="cursor: pointer" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img Style="height: 40px; width: 40px; border: 1px solid #6e6e6e; border-radius: 50%" src={`${process.env.REACT_APP_URL + JSON.parse(localStorage.getItem("userData")).picture}`} type="button" />
                                                        {/* <i class="fa-solid fa-sort-down ms-1"></i> */}
                                                    </div>
                                                    <ul class="dropdown-menu dropDownBody">
                                                        <div class="row" Style="margin-left: 10px; justify-content: center; align-items: center;">
                                                            <div class="col-lg-4" Style="width: 72px; height: 72px; margin-left: 10px;display: flex; justify-content: center;  align-items: center;">
                                                                <Link to={"/account"} ><img Style="border-radius: 50% !important;cursor:pointer; width: 100%" src={`${process.env.REACT_APP_URL + JSON.parse(localStorage.getItem("userData")).picture}`} alt="image profile" class="avatar-img rounded" /></Link>

                                                            </div>
                                                            <div class="col-lg-8">
                                                                <div class="name" Style="font-size: 14px;">
                                                                    <span Style="cursor:pointer;word-wrap:break-word"><Link to={"/account"} >{JSON.parse(localStorage.getItem("userData")).first_name + " " + JSON.parse(localStorage.getItem("userData")).last_name}</Link></span>
                                                                </div>
                                                                <div class="email" Style="font-size: 12px;">
                                                                    <span Style="word-wrap:break-word">{JSON.parse(localStorage.getItem("userData")).email}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <li>
                                                            <Link to={"/account"} class="dropdown-item-custom">Profile</Link>
                                                            <i class="fa-solid fa-user"></i>
                                                        </li>
                                                        <li>
                                                            <Link to={"/settings"} class="dropdown-item-custom">Settings</Link>
                                                            <i class="fa-solid fa-gear"></i>
                                                        </li>
                                                        <li>
                                                            <Link to={"/cart"} class="dropdown-item-custom">Cart</Link>
                                                            <i class="fa-solid fa-cart-shopping"></i>
                                                        </li>
                                                        {/* <li>
                                                            <Link to={"/account"} class="dropdown-item-custom">Wishlist</Link>
                                                            <i class="fa-solid fa-heart"></i>
                                                        </li> */}
                                                        <li>
                                                            <Link to={"/orders"} class="dropdown-item-custom">Orders</Link>
                                                            <i class="fa-solid fa-box"></i>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item-custom" onClick={() => { localStorage.removeItem("userData"); window.location.reload() }} >Log Out</a>
                                                            <i class="fa-solid fa-arrow-right-from-bracket"></i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="header-tools header-tools-sticky d-none d-flex h-100 justify-content-center">
                                        <div className=" d-flex align-self-center">
                                            <Link to={"/login"} type="button" class="button-30 me-2">Log In</Link>
                                            <Link to={"/register"} type="button" class="button-29">Sign Up</Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </div>
                {/* header menu */}
            </header>
        </>
    )
}

export default Header