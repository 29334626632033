import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import orderSuccess from "../../assets/lottie/order-success.json"
import orderFailed from "../../assets/lottie/failed.json"
import {  useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeAllCart } from '../../services/CartSlice';

function OrderSuccess() {
  var { orderId } = useParams()
  const queryParameters = new URLSearchParams(window.location.search)
  const [orderType, setorderType] = useState({
    type:"",
    msg: ""
  })

  const Navigate=useNavigate()
  const dispatch=useDispatch()

  useEffect(() => {
    if (queryParameters.get("payment_intent")) {
      insertOrder(queryParameters.get("payment_intent")).then((data) => {
        if(data.status==="success"){
          setorderType({
            type: "success",
            msg: data.message
          })
          dispatch(removeAllCart([]))
        }else if(data.status==="failed" && data.status_code==402){
          setorderType({
            type: "success",
            msg: data.message
          })
        }else{
          setorderType({
            type: "failed",
            msg: data.message
          })
        }
      })
    }
  }, [])


  async function insertOrder(paymentIntend) {
    const response = await fetch(`${process.env.REACT_APP_URL}order_api/order/insertOrders`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        payment_intent: paymentIntend,
        orderid: orderId,
        token: JSON.parse(localStorage.getItem("userData")).token
      })
    });
    return response.json();

  }

  return (
    <div>
      {orderType.type=="success"?<>
        <div Style={"height: 500px;"}>
          <Lottie animationData={orderSuccess} loop={true} Style={"height: 100%"} />
        </div>
        <h4 className='text-center mb-5'>{orderType.msg}</h4>
        <div className='d-flex justify-content-center align-items-center mt-3 mb-5'>
          <button className='btn btn-success ' onClick={()=>{Navigate("/")}}><i class="fa-solid fa-arrow-left me-2"></i>Back to Home page</button>
        </div>
      </>:<>
        <div Style={"height: 400px;"}>
          <Lottie animationData={orderFailed} loop={true} Style={"height: 100%"} />
        </div>
        <h4 className='text-center mb-5'>Your order has been failed! Please try again later.</h4>
        <div className='d-flex justify-content-center align-items-center mt-3 mb-5'>
          <button className='btn btn-success ' onClick={()=>{Navigate("/")}}><i class="fa-solid fa-arrow-left me-2"></i>Back to Home page</button>
        </div>
      </>}
    </div>
  )
}

export default OrderSuccess