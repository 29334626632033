import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import HomeProducts from './HomeProducts';
import NewArrivals from './NewArrivals';
import useProductFetch from '../../hooks/useProductFetch';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../services/CartSlice';

function ProductDetails({setAlert, alert}) {
    var { prodId } = useParams()

    const { prodDetails, prodDetLoading, error, productReviews } = useProductFetch(prodId)

    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);
    const [nav1, setNav1] = useState(sliderRef1);
    const [nav2, setNav2] = useState(sliderRef2);

    const [activeTab, setActiveTab] = useState("Description")

    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollTo(0, 0)
        setNav2(sliderRef2);
        setNav1(sliderRef1);
        console.log(prodId)
    }, []);

    useEffect(() => {
      document.getElementById("productDesc").innerHTML=prodDetails.product_specification
    }, [prodDetails])
    

    // console.log(prodDetails)

    function addToCartFunc(item) {
        var itemDetails = { ...item, referral_id: "xyz" }
        updateCart(item.product_id, JSON.parse(localStorage.getItem("userData")).token, "add", "").then((resData) => {
            try {
              // console.log(resData.json())
              if (resData.status == "success") {
                dispatch(addToCart(itemDetails))
                setAlert({...alert, open: true, type: "success", alertType: "modal", msg: "Added to cart Successfully!"})
              } else {
                // bottomsheet need out of stock
                setAlert({...alert, open: true, type: "danger", alertType: "modal", msg: resData.message})

              }
            } catch (error) {
              console.log(error)
            }
          }).catch(error => {
            console.log(error);
          })
    }

    async function updateCart(item, token, type, referId) {
        const response = await fetch(`${process.env.REACT_APP_URL}cart_api/cart/cartUpdateNative`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                token: token,
                product_id: item,
                type: type,
                quantity: 1,
                referId: referId
            })
        });
        return response.json();
    }



    return (
        <div>
            {prodDetails ? <>
                <section class="product-details-area mtb-60px ">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="">
                                    {/* {prodDetails.product_images ? <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                                        {prodDetails.product_images.map((item, index) => {
                                            return <div key={index} class="zoompro-border zoompro-span">
                                                <img class="img-products" src={process.env.REACT_APP_URL + item.image} data-zoom-image={process.env.REACT_APP_URL + item.image} alt={prodDetails.product_name} />
                                            </div>
                                        })}
                                    </Slider> : ""}
                                    {prodDetails.product_images ? <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true}>
                                        {prodDetails.product_images.map((item, index) => {
                                            return <div key={index} class="single-slide-item">
                                                <img class="img-products-nav" data-image={process.env.REACT_APP_URL + item.image} src={process.env.REACT_APP_URL + item.image} data-zoom-image={process.env.REACT_APP_URL + item.image} alt={prodDetails.product_name} />
                                            </div>
                                        })}
                                    </Slider> : ""} */}
                                    <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                                        {prodDetails.product_images?.map((item, index) => {
                                            return <div key={index} class="zoompro-border zoompro-span">
                                                <img class="img-products" src={process.env.REACT_APP_URL + item.image} data-zoom-image={process.env.REACT_APP_URL + item.image} alt={prodDetails.product_name} />
                                            </div>
                                        })}
                                    </Slider>
                                    <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true}>
                                        {prodDetails.product_images?.map((item, index) => {
                                            return <div key={index} class="single-slide-item">
                                                <img class="img-products-nav" data-image={process.env.REACT_APP_URL + item.image} src={process.env.REACT_APP_URL + item.image} data-zoom-image={process.env.REACT_APP_URL + item.image} alt={prodDetails.product_name} />
                                            </div>
                                        })}
                                    </Slider>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="product-details-content">
                                    <h2>{prodDetails.product_name}</h2>
                                    <div class="pro-details-rating-wrap">
                                        <div class="rating-product">
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                            <i class="ion-android-star"></i>
                                        </div>
                                        <span class="read-review"><a class="reviews" href="#">Read reviews (1)</a></span>
                                    </div>
                                    <div class="pricing-meta">
                                        <ul>
                                            <li class="old-price">₹{Number(prodDetails.price).toFixed(2)}</li>
                                            <li class="cuttent-price">₹{Number(prodDetails.discounted_price).toFixed(2)}</li>
                                            <li class="discount-flag">save {((Number(prodDetails.price) - Number(prodDetails.discounted_price)) / Number(prodDetails.price) * 100).toFixed(2)}%</li>
                                        </ul>
                                    </div>
                                    <div class="pro-details-list">
                                        <p>{prodDetails.description}</p>
                                    </div>
                                    <div class="pro-details-quality mt-0px">
                                        <div class="pro-details-cart btn-hover">
                                            <a onClick={() => { addToCartFunc(prodDetails) }}>  Add To Cart</a>
                                        </div>
                                    </div>
                                    <div class="pro-details-wish-com">
                                        <div class="pro-details-wishlist">
                                            <a><i class="ion-android-favorite-outline"></i>Add to wishlist</a>
                                        </div>
                                    </div>
                                    <div class="pro-details-social-info">
                                        <span>Share</span>
                                        <div class="social-info">
                                            <ul>
                                                <li>
                                                    <a title="Facebook" href="#"><i class="ion-social-facebook"></i></a>
                                                </li>
                                                <li>
                                                    <a title="Twitter" href="#"><i class="ion-social-twitter"></i></a>
                                                </li>
                                                <li>
                                                    <a title="Google+" href="#"><i class="ion-social-google"></i></a>
                                                </li>
                                                <li>
                                                    <a title="Instagram" href="#"><i class="ion-social-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="pro-details-policy">
                                        <ul>
                                            <li><img src="../images/icons/policy.png" alt="" /><span>Security Policy (Edit With Customer Reassurance Module)</span></li>
                                            <li><img src="../images/icons/policy-2.png" alt="" /><span>Delivery Policy (Edit With Customer Reassurance Module)</span></li>
                                            <li><img src="../images/icons/policy-3.png" alt="" /><span>Return Policy (Edit With Customer Reassurance Module)</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="description-review-area mb-50px bg-light-gray-3 ptb-50px">
                    <div class="container">
                        <div class="description-review-wrapper">
                            <div class="description-review-topbar nav">
                                <a class={`${activeTab === "Description" ? "tabBtn active" : "tabBtn "}`} onClick={() => { setActiveTab("Description") }}>Description</a>
                                <a class={`${activeTab === "Details" ? "tabBtn active" : "tabBtn "}`} onClick={() => { setActiveTab("Details") }}>Product Details</a>
                                <a class={`${activeTab === "Reviews" ? "tabBtn active" : "tabBtn "}`} onClick={() => { setActiveTab("Reviews") }}>Reviews (2)</a>
                            </div>
                            <div class="tab-content description-review-bottom">
                                <div id="des-details2" class={`${activeTab === "Description" ? "" : "d-none"}`}>
                                    <div class="product-anotherinfo-wrapper">
                                        <p>{prodDetails.description}</p>
                                    </div>
                                </div>
                                <div id="des-details1" class={`${activeTab === "Details" ? "" : "d-none"}`}>
                                    <div class="product-description-wrapper ck ck-content" id="productDesc">
                                        <p>{prodDetails.product_specification}</p>
                                    </div>
                                </div>
                                <div id="des-details3" class={`${activeTab === "Reviews" ? "" : "d-none"}`}>
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <div class="review-wrapper">
                                                <div class="single-review">
                                                    <div class="review-img">
                                                        <img src="../images/review-image/1.png" alt="" />
                                                    </div>
                                                    <div class="review-content">
                                                        <div class="review-top-wrap">
                                                            <div class="review-left">
                                                                <div class="review-name">
                                                                    <h4>White Lewis</h4>
                                                                </div>
                                                                <div class="rating-product">
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                </div>
                                                            </div>
                                                            <div class="review-left">
                                                                <a href="#">Reply</a>
                                                            </div>
                                                        </div>
                                                        <div class="review-bottom">
                                                            <p>
                                                                Vestibulum ante ipsum primis aucibus orci luctustrices posuere cubilia Curae Suspendisse viverra ed viverra. Mauris ullarper euismod vehicula. Phasellus quam nisi, congue id nulla.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="single-review child-review">
                                                    <div class="review-img">
                                                        <img src="../images/review-image/2.png" alt="" />
                                                    </div>
                                                    <div class="review-content">
                                                        <div class="review-top-wrap">
                                                            <div class="review-left">
                                                                <div class="review-name">
                                                                    <h4>White Lewis</h4>
                                                                </div>
                                                                <div class="rating-product">
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                    <i class="ion-android-star"></i>
                                                                </div>
                                                            </div>
                                                            <div class="review-left">
                                                                <a href="#">Reply</a>
                                                            </div>
                                                        </div>
                                                        <div class="review-bottom">
                                                            <p>Vestibulum ante ipsum primis aucibus orci luctustrices posuere cubilia Curae Sus pen disse viverra ed viverra. Mauris ullarper euismod vehicula.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="ratting-form-wrapper pl-50">
                                                <h3>Add a Review</h3>
                                                <div class="ratting-form">
                                                    <form action="#">
                                                        <div class="star-box">
                                                            <span>Your rating:</span>
                                                            <div class="rating-product">
                                                                <i class="ion-android-star"></i>
                                                                <i class="ion-android-star"></i>
                                                                <i class="ion-android-star"></i>
                                                                <i class="ion-android-star"></i>
                                                                <i class="ion-android-star"></i>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="rating-form-style mb-10">
                                                                    <input placeholder="Name" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="rating-form-style mb-10">
                                                                    <input placeholder="Email" type="email" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="rating-form-style form-submit">
                                                                    <textarea name="Your Review" placeholder="Message"></textarea>
                                                                    <input type="submit" value="Submit" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="arrival-area single-product-nav mb-20px">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="section-title">
                                    <h2 class="section-heading">12 Other Products In The Same Category:</h2>
                                </div>
                            </div>
                        </div>
                        <NewArrivals />
                    </div>
                </div>
            </> : ""}
        </div>
    )
}

export default ProductDetails