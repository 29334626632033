import React, { useEffect } from 'react'

function ContactUs({alert, setAlert}) {

  function formSubmit(event){
    console.log("kjdh")
    event.preventDefault()
    setAlert({...alert, open: true, type: "success", alertType: "modal", msg: "Your query has been submitted successfully! We will connect you soon. Thank You!"})
  }
  
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <div class="contact-area mtb-50px">
      <div class="container">
        <div class="contact-map mb-10">
          <div class="mapouter">
            <div class="gmap_canvas">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3490.4245866503384!2d79.39183437530238!3d28.97478687547967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a07f934bd4062b%3A0xd441d0ab76c303f5!2sMain%20Market%20-%20Galla%20Mandi%20Rd%2C%20Bhurarani%2C%20Rudrapur%2C%20Uttarakhand%20263153!5e0!3m2!1sen!2sin!4v1709917499446!5m2!1sen!2sin" width="600" height="450" Style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <a href="https://sites.google.com/view/maps-api-v2/mapv2"></a>
            </div>
          </div>
        </div>
        <div class="custom-row-2">
          <div class="col-lg-4 col-md-5 mb-lm-30px">
            <div class="contact-info-wrap">
              <div class="single-contact-info">
                <div class="contact-icon">
                  <i class="ion-android-call"></i>
                </div>
                <div class="contact-info-dec">
                  <p><a href="tel:(+91)8193020002"> (+91)8193020002</a></p>
                </div>
              </div>
              <div class="single-contact-info">
                <div class="contact-icon">
                  <i class="ion-android-globe"></i>
                </div>
                <div class="contact-info-dec">
                  <p><a href="mailto://info@pc2u.in">info@pc2u.in</a></p>
                </div>
              </div>
              <div class="single-contact-info">
                <div class="contact-icon">
                  <i class="ion-android-pin"></i>
                </div>
                <div class="contact-info-dec">
                  <p>Main Market</p>
                  <p>Rudrapur Udham Singh Nagar, Uttrakhand-263153.</p>
                </div>
              </div>
              <div class="contact-social">
                <h3>Follow Us</h3>
                <div class="social-info">
                  <ul>
                    <li>
                      <a href="#"><i class="ion-social-facebook"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="ion-social-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="ion-social-youtube"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="ion-social-google"></i></a>
                    </li>
                    <li>
                      <a href="#"><i class="ion-social-instagram"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-7">
            <div class="contact-form">
              <div class="contact-title mb-30">
                <h2>Get In Touch</h2>
              </div>
              <form class="contact-form-style" id="contact-form">
                <div class="row">
                  <div class="col-lg-6">
                    <input name="name" placeholder="Name*" type="text" />
                  </div>
                  <div class="col-lg-6">
                    <input name="email" placeholder="Email*" type="email" />
                  </div>
                  <div class="col-lg-12">
                    <input name="subject" placeholder="Subject*" type="text" />
                  </div>
                  <div class="col-lg-12">
                    <textarea name="message" placeholder="Your Message*"></textarea>
                    <button class="submit" type="submit" onClick={formSubmit}>SEND</button>
                  </div>
                </div>
              </form>
              <p class="form-messege"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs