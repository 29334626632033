import React from 'react'
import Slider from "react-slick";

function HeroBamnner() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="">
            <Slider {...settings}>
                {/* Single Slider  */}
                <div
                    className="single-slide slider-height-1 bg-img d-flex"
                    data-bg-image="../images/slider-image/sample-1.jpg"
                >
                    <div className="container align-self-center">
                        <div className="slider-content-1 slider-animated-1 text-left pl-60px">
                            <h1 className="animated color-black">
                                Xbox One Pro <br />
                                Wireless Controller
                            </h1>
                            <p className="animated color-gray">Revolution Pro Controller.</p>
                            <a href="shop-4-column.html" className="shop-btn animated">
                                SHOP NOW
                            </a>
                        </div>
                    </div>
                </div>
                {/* Single Slider  */}
                <div
                    className="single-slide slider-height-1 bg-img d-flex"
                    data-bg-image="../images/slider-image/sample-2.jpg"
                >
                    <div className="container align-self-center">
                        <div className="slider-content-1 slider-animated-2 text-left pl-60px">
                            <h1 className="animated color-black">
                                Bobovr Z4 Virtual <br />
                                Reality 3D Glasses
                            </h1>
                            <p className="animated color-gray">
                                Virtual reality through a new lens
                            </p>
                            <a href="shop-4-column.html" className="shop-btn animated">
                                SHOP NOW
                            </a>
                        </div>
                    </div>
                </div>
                {/* Single Slider  */}
                <div
                    className="single-slide slider-height-1 bg-img d-flex"
                    data-bg-image="../images/slider-image/sample-3.jpg"
                >
                    <div className="container align-self-center">
                        <div className="slider-content-1 slider-animated-3 text-left pl-60px">
                            <h1 className="animated color-black">
                                Portable Wireless <br />
                                Bluetooth Speaker
                            </h1>
                            <p className="animated color-gray">With Colorful LED Light</p>
                            <a href="shop-4-column.html" className="shop-btn animated">
                                SHOP NOW
                            </a>
                        </div>
                    </div>
                </div>
                {/* Single Slider  */}
            </Slider>
        </div>
    )
}

export default HeroBamnner