import { React, useState, useEffect } from 'react'

const useHomeFetch = () => {


    const [popularProduct, setPopularProduct] = useState([])
    const [trendingProduct, setTrendingProduct] = useState([])
    const [newArrivalProduct, setNewArrivalProduct] = useState([])
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [prodListLoading, setprodListLoading] = useState(true)
    const [error, setError] = useState(false);
    const [topBanner, setTopBanner] = useState([])

    async function fetchPhysicalHomeData() {
        const response = await fetch(`${process.env.REACT_APP_URL}product_api/product/productList`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }


    async function fetchPopularProductData() {
        const response = await fetch(`${process.env.REACT_APP_URL}topProduct_api/topProduct/popularProduct`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }
    async function fetchTrendingProductData() {
        const response = await fetch(`${process.env.REACT_APP_URL}topProduct_api/topProduct/trendingProduct`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }

    async function fetchNewArrivalProductData() {
        const response = await fetch(`${process.env.REACT_APP_URL}topProduct_api/topProduct/newArrivalProduct`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }

    async function fetchBannerDataData() {
        const response = await fetch(`${process.env.REACT_APP_URL}banner_api/banner/getHeroBanner`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }


    const fetchData = (homeType) => {
        setLoading(true);
        setprodListLoading(true)
        try {
            // fetchBannerDataData().then((imgData) => {
            //     var allImmage = [];
            //     if (imgData.status == "success") {
            //         imgData.banner.forEach(element => {
            //             allImmage.push(process.env.REACT_APP_URL + element.banner_path)
            //         });
            //         setTopBanner(allImmage)
            //     }

            // })
            fetchPhysicalHomeData().then((data) => {
                setProducts(data.all_products)
            })
            fetchPopularProductData().then((proddata) => {
                setPopularProduct(proddata.popular_products)
            })
            fetchNewArrivalProductData().then((proddata) => {
                setNewArrivalProduct(proddata.newArrivalProduct)
            })
            fetchTrendingProductData().then((proddata) => {
                setTrendingProduct(proddata.trendingProduct)
                setprodListLoading(false)
                setLoading(false);
            })


        } catch (error) {
            setError(error)
        }
        //    console.log(products)
    }

    useEffect(() => {
        fetchData()
    }, [])
    


    return { products, loading, popularProduct,trendingProduct, prodListLoading, newArrivalProduct, topBanner, error, fetchData };
}

export default useHomeFetch