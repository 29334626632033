import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Lottie from "lottie-react";
import noProduct from "../../assets/lottie/noproduct.json"
import useProByCatFetch from '../../hooks/useProByCatFetch';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../services/CartSlice';

function AllProducts({setAlert}) {
    var { type, typeId } = useParams();
    const dispatch=useDispatch()

    const [trendingCatProduct, setTrendingCatProduct] = useState([]);
    const [prodDetLoading, setProdDetloading] = useState(true);
    const [error, setError] = useState(false);

    async function fetchProdDetailsData() {
        const response = await fetch(`${process.env.REACT_APP_URL}topProduct_api/topProduct/category_products`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "Post",
                body: JSON.stringify({
                    cat_id: typeId
                })
            })
        return response.json()
    }


    const fetchData = () => {
        setProdDetloading(true);
        try {
            fetchProdDetailsData().then((data) => {
                console.log(data)
                if (data.status == "success") {
                    setTrendingCatProduct(data.best_seller)
                    setTimeout(() => {
                        setProdDetloading(false);
                    }, 200);
                }
            })
        } catch (error) {
            setError(error)
        } finally {
            // setProdDetloading(false);
        }
    }

    useEffect(() => {
        fetchData()
        //   console.log(products)
        document.getElementById("categoryBtn").click()
        document.getElementById("categoryBtnMobile").click()
    }, [typeId])



    useEffect(() => {
        console.log(trendingCatProduct);
    }, [typeId, trendingCatProduct])


    function addToCartFunc(item) {
        var itemDetails = { ...item, referral_id: "xyz" }
        updateCart(item.product_id, JSON.parse(localStorage.getItem("userData")).token, "add", "").then((resData) => {
            try {
              // console.log(resData.json())
              if (resData.status == "success") {
                dispatch(addToCart(itemDetails))
                setAlert({...alert, open: true, type: "success", alertType: "modal", msg: "Added to cart Successfully!"})
              } else {
                // bottomsheet need out of stock
                setAlert({...alert, open: true, type: "danger", alertType: "modal", msg: resData.message})

              }
            } catch (error) {
              console.log(error)
            }
          }).catch(error => {
            console.log(error);
          })
    }

    async function updateCart(item, token, type, referId) {
        const response = await fetch(`${process.env.REACT_APP_URL}cart_api/cart/cartUpdateNative`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                token: token,
                product_id: item,
                type: type,
                quantity: 1,
                referId: referId
            })
        });
        return response.json();
    }


    return (
        <>
            {trendingCatProduct.length > 0 ?
                <>
                    <div className='container'>
                        <div className='row mt-4'>
                            {trendingCatProduct.map((item, index) => {
                                return <div key={index} className='popular-product-card col-6 col-md-3 col-lg-4'>
                                    {/* Single Item */}
                                    <div className="list-product text-center mb-30px">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <Link to={`/product/${item.product_id}`} className="thumbnail">
                                                    <img
                                                        className="popular-product-img first-img"
                                                        src={process.env.REACT_APP_URL + item.thumbnail}
                                                        alt={item.product_name}
                                                    />
                                                    <img
                                                        className="popular-product-img second-img"
                                                        src={process.env.REACT_APP_URL + item.thumbnail}
                                                        alt={item.product_name}
                                                    />
                                                </Link>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-{((Number(item.price) - Number(item.discounted_price)) / Number(item.price)).toFixed(2) * 100}%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>{item.category_name}</span>
                                                </a>
                                                <h2>
                                                    <Link to={`/product/${item.product_id}`} className="product-link">
                                                        {item.product_name.length > 65 ? item.product_name.slice(0, 64) + "..." : item.product_name}
                                                    </Link>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">₹{Number(item.price).toFixed(2)}</li>
                                                        <li className="current-price">₹{Number(item.discounted_price).toFixed(2)}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                {<a onClick={() => { addToCartFunc(item) }} className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </>
                :
                <div>
                    <div className='' Style="height: 500px">
                        <Lottie animationData={noProduct} loop={true} Style={"height: 100%"} />
                    </div>
                    <h4 className='d-flex justify-content-center mb-5 mt-4'>No product found!</h4>
                </div>}
        </>
    )
}

export default AllProducts