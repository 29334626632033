import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Register() {
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    referralCode: "",
    confpassword: ""
  })
  const [validationError, setValidationError] = useState({
    type: "",
    message: ""
  })
  const Navigate=useNavigate();

  async function userRegisterFunc() {
    const response = await fetch(`${process.env.REACT_APP_URL}account/login/create_account`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        'first_name': credentials.firstName,
        'last_name': credentials.lastName,
        'email': credentials.email,
        'phone': credentials.phone,
        'password': credentials.password,
        'referral_code': credentials.referralCode
      })
    })
    return response.json()
  }

  function userRegister() {
    if (!credentials.firstName) {
      setValidationError({ ...validationError, type: "firstname", message: "Please enter first name." })
      document.getElementsByName("user-first-name")[0].focus()
    }
    else if (!credentials.lastName) {
      setValidationError({ ...validationError, type: "lastname", message: "Please enter Last name." })
      document.getElementsByName("user-last-name")[0].focus()
    }
    else if (!credentials.email) {
      setValidationError({ ...validationError, type: "email", message: "Please enter your email." })
      document.getElementsByName("user-email")[0].focus()
    }
    else if (!credentials.email.includes("@")) {
      setValidationError({ ...validationError, type: "email", message: "Please enter valid email." })
      document.getElementsByName("user-email")[0].focus()
    }
    else if (!credentials.phone) {
      setValidationError({ ...validationError, type: "phone", message: "Please enter your phone number." })
      document.getElementsByName("user-phone")[0].focus()
    }
    else if (!credentials.password) {
      setValidationError({ ...validationError, type: "password", message: "Please enter your password." })
      document.getElementsByName("user-password")[0].focus()
    }
    else if (credentials.password.length<7) {
      setValidationError({ ...validationError, type: "password", message: "Password should be 8 length of charecter." })
      document.getElementsByName("user-password")[0].focus()
    }
    else if (credentials.password != credentials.confpassword) {
      setValidationError({ ...validationError, type: "confirmpassword", message: "Password should be same!" })
      document.getElementsByName("user-confirm-password")[0].focus()
    }else{
      userRegisterFunc().then((userData)=>{
        if(userData.status="success"){
          localStorage.setItem("userData", JSON.stringify(userData))
          Navigate("/")
        }else{
          console.warn(userData.message)
        }
      }).catch((err)=>{
        console.log(err.message)
      })
    }
  }

  return (
    <>
      <div class="breadcrumb-area">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="breadcrumb-content">
                <ul class="nav">
                  <li><a href="index.html">Home</a></li>
                  <li>Register</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-register-area mb-50px mt-40px">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-12 ms-auto me-auto">
              <div class="login-register-wrapper">
                <div class="login-register-tab-list nav">
                  <a class="active" data-bs-toggle="tab" href="#lg1">
                    <h4>register</h4>
                  </a>
                </div>
                <div class="tab-content">
                  <div id="lg1" class="tab-pane active">
                    <div class="login-form-container">
                      <div class="login-register-form">
                        <input type="text" className={`${validationError.type == "firstname" ? "mb-2" : ""}`} name="user-first-name" value={credentials.firstName} onChange={(e) => { setCredentials({ ...credentials, firstName: e.target.value }) }} placeholder="First Name" />
                        <p className={`${validationError.type == "firstname" ? "d-block text-danger" : "d-none"}`}>{validationError.message}</p>

                        <input type="text" className={`${validationError.type == "lastname" ? "mb-2" : ""}`} name="user-last-name" value={credentials.lastName} onChange={(e) => { setCredentials({ ...credentials, lastName: e.target.value }) }} placeholder="Last Name" />
                        <p className={`${validationError.type == "lastname" ? "d-block text-danger" : "d-none"}`}>{validationError.message}</p>

                        <input type="email" className={`${validationError.type == "email" ? "mb-2" : ""}`} name="user-email" value={credentials.email} onChange={(e) => { setCredentials({ ...credentials, email: e.target.value }) }} placeholder="Email" />
                        <p className={`${validationError.type == "email" ? "d-block text-danger" : "d-none"}`}>{validationError.message}</p>

                        <input type="number" className={`${validationError.type == "phone" ? "mb-2" : ""}`} name="user-phone" value={credentials.phone} onChange={(e) => { setCredentials({ ...credentials, phone: e.target.value }) }} placeholder="Mobile" />
                        <p className={`${validationError.type == "phone" ? "d-block text-danger" : "d-none"}`}>{validationError.message}</p>

                        <input type="password" className={`${validationError.type == "password" ? "mb-2" : ""}`} name="user-password" value={credentials.password} onChange={(e) => { setCredentials({ ...credentials, password: e.target.value }) }} placeholder="Password" />
                        <p className={`${validationError.type == "password" ? "d-block text-danger" : "d-none"}`}>{validationError.message}</p>

                        <input type="password" className={`${validationError.type == "confirmpassword" ? "mb-2" : ""}`} name="user-confirm-password" value={credentials.confpassword} onChange={(e) => { setCredentials({ ...credentials, confpassword: e.target.value }) }} placeholder="Confirm Password" />
                        <p className={`${validationError.type == "confirmpassword" ? "d-block text-danger" : "d-none"}`}>{validationError.message}</p>

                        <div class="button-box">
                          <div class="login-toggle-btn d-flex justify-content-between">
                            <div className=" d-flex justify-content-center align-items-center">
                              <input type="checkbox" />
                              <a class="flote-none ms-1" href="javascript:void(0)">Remember me</a>
                            </div>
                            <div className=" d-flex justify-content-center align-items-center">
                              <p className='mb-0'>Already have an account!</p>
                              <Link to={"/login"} className='ms-2 text-primary fs-6 text-underline' Style="text-decoration: underline !important;">Login</Link>
                            </div>
                          </div>
                          <button type="submit" onClick={userRegister}><span>Register</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register