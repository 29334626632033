import React from 'react'
import Slider from 'react-slick'

function VendorSlider() {
    const settings = {
        infinite: true,
        slidesToShow: 5,
        arrows: false,
        slidesToScroll: 1,
        initialSlide: 0,
        speed: 800,
        easing: 'linear',
        dots: false,
        infinite: true,
        responsive: [{
            breakpoint: 1200,
            Settings: {
                slidesToShow: 4,
                initialSlide: 0,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                initialSlide: 0,
                slidesToScroll: 1,
                infinite: true
            }
        }
        ]
    }
    return (
        <div className="brand-slider">
            <Slider {...settings}>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/1.png"
                            alt=""
                        />
                    </a>
                </div>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/2.png"
                            alt=""
                        />
                    </a>
                </div>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/3.png"
                            alt=""
                        />
                    </a>
                </div>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/4.png"
                            alt=""
                        />
                    </a>
                </div>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/5.png"
                            alt=""
                        />
                    </a>
                </div>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/1.png"
                            alt=""
                        />
                    </a>
                </div>
                <div className="brand-slider-item">
                    <a href="#">
                        <img
                            className=" img-responsive"
                            src="../images/brand-logo/2.png"
                            alt=""
                        />
                    </a>
                </div>
            </Slider>
        </div>
    )
}

export default VendorSlider