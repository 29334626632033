import {React,useState, useEffect} from 'react'

const useCategoryFetch = () => {
    const [allCategory, setAllCategory] = useState([]);
    const [catloading, setCatloading] = useState(false);
    const [error, setError] = useState(false);

    async function fetchAllCategoryData(){
        const response = await fetch(`${process.env.REACT_APP_URL}product_api/product/categoryList`,{
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        return response.json()
    }

    
    const fetchData=()=>{
        setCatloading(true);
       try{
         fetchAllCategoryData().then((data)=>{
            setAllCategory(data.all_category)
            setCatloading(false);
         })
       }catch(error){
            setError(error)
       }
    }

    useEffect(() => {
      fetchData()
    //   console.log(products)
    }, [])
    

    return {allCategory, catloading, error};
}

export default useCategoryFetch