import React, { useEffect } from 'react'
import privacyImg from "../../assets/gifs/privacy-policy.gif"

function PrivacyPolicy() {
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])
    return (
        <section class="about-area mtb-50px">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about-left-image mb-md-30px mb-lm-30px ">
                            <img src={privacyImg} alt="" class="img-responsive w-100" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content">
                            <div class="about-title">
                                <h2>Privacy Policy</h2>
                            </div>
                            <p class="mb-30px">
                                At our Computer Shop Ecommerce, we understand the importance of privacy and are committed to protecting the personal information of our customers. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to safeguard it.
                            </p>
                            <h5 class="mb-30px">
                                Information We Collect:
                            </h5>
                            <p>
                                <span className='subHead'>Personal Information:</span> When you make a purchase or create an account on our website, we may collect personal information such as your name, email address, phone number, shipping address, and payment details.
                            </p>
                            <p>
                                <span className='subHead'>Device Information:</span> We may collect information about the device you use to access our website, including IP address, browser type, and operating system.
                            </p>
                            <p>
                                <span className='subHead'>Cookies:</span> We use cookies to enhance your browsing experience and track website usage. These cookies may collect information such as your browsing history, preferences, and session data.
                            </p>
                            <p>
                                <span className='subHead'>Personal Information:</span> When you make a purchase or create an account on our website, we may collect personal information such as your name, email address, phone number, shipping address, and payment details.
                            </p>
                            <h5 class="mb-30px mt-30px">
                                How We Use Your Information:
                            </h5>
                            <p>
                                <span className='subHead'>Order Processing:</span> We use your personal information to process orders, provide customer support, and communicate with you about your purchases.
                            </p>
                            <p>
                                <span className='subHead'>Improving User Experience:</span> We may use the information collected to personalize your experience on our website, tailor product recommendations, and improve our services.
                            </p>
                            <p>
                                <span className='subHead'>Marketing and Communication:</span> With your consent, we may send you promotional emails about new products, special offers, and updates. You can opt-out of these communications at any time.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mt-50px">
                    <div class="col-md-4 mb-lm-30px">
                        <div class="single-about">
                            <h4>Third-Party Disclosure</h4>
                            <p>
                                We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except for trusted third parties who assist us in operating our website, conducting business, or servicing you.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-lm-30px">
                        <div class="single-about">
                            <h4>Your Rights</h4>
                            <p>
                                You have the right to access, update, or delete your personal information stored in our systems. If you would like to exercise any of these rights or have any questions about our Privacy Policy, please contact us using the information.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="single-about">
                            <h4>Data Security</h4>
                            <p>
                                We take the security of your personal information seriously and implement various security measures to protect it from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, secure data storage, and regular security audits.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy