import React, { useEffect } from 'react'
import MainHome from './home/MainHome'
import { useDispatch } from 'react-redux'
import { getcartAllData } from '../services/CartSlice'

// import "../assets/js/main.js"

function Home({products, popularProduct, newArrivalProduct, trendingProduct, loading, setAlert, alert}) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    const dispatch=useDispatch()

    useEffect(()=>{
        if(localStorage.getItem("userData")){
            dispatch(getcartAllData(JSON.parse(localStorage.getItem("userData")).token))
        }
    },[])

    return (
        <div>
            <MainHome products={products} popularProduct={popularProduct} trendingProduct={trendingProduct} newArrivalProduct={newArrivalProduct} loading={loading} setAlert={setAlert} alert={alert}/>
        </div>
    )
}

export default Home