import { React, useState, useEffect } from 'react'

const useProByCatFetch = (catId) => {
    const [trendingCatProduct, setTrendingCatProduct] = useState([]);
    const [prodDetLoading, setProdDetloading] = useState(true);
    const [error, setError] = useState(false);

    async function fetchProdDetailsData() {
        const response = await fetch(`${process.env.REACT_APP_URL}topProduct_api/topProduct/category_products`,
         {
            headers: {
                "Content-Type": "application/json"
            },
            method: "Post",
            body: JSON.stringify({
                cat_id: catId
            })
        })
        return response.json()
    }


    const fetchData = () => {
        setProdDetloading(true);
        try {
            fetchProdDetailsData().then((data) => {
                console.log(data)
                if(data.status=="success"){
                    setTrendingCatProduct(data.best_seller)
                    setTimeout(() => {
                        setProdDetloading(false);
                    }, 200);
                }
            })
        } catch (error) {
            setError(error)
        } finally {
            // setProdDetloading(false);
        }
    }

    useEffect(() => {
        fetchData()
        //   console.log(products)
    }, [])


    return { prodDetLoading, error, trendingCatProduct };
}

export default useProByCatFetch