import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./CartSlice"

// import counterReducer from "./cartSlice"

export default configureStore({
    reducer:  {
        counter: counterReducer,
      }
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //     serializableCheck: false
    // }),
});
